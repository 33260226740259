import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectGeneral({value, setter, valueList, label = '', generalLabel = 'Selectionnez'}) {
  

  const handleChange = (event) => {
    setter(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{backgroundColor: 'white', minWidth: 150 }}>
        {label.length === 0 ? null : <InputLabel id="demo-simple-select-label">{label.length > 0 ? label : 'Selectionnez'}</InputLabel>}
        {valueList.length > 0 ? 
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={value}
            label={label.length > 0 ? label : 'selectionnez'}
            onChange={handleChange}
            autoWidth
          >
            <MenuItem disabled value="-">
              <em>{generalLabel}</em>
            </MenuItem>
            {valueList.map((listItem,index) => <MenuItem key={index} sx={{minWidth: 150 }} value={listItem.id}>{listItem.value}</MenuItem>)}
          </Select>
          :
          <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={handleChange}
          autoWidth
        >
        </Select>
        }
      </FormControl>
    </div>
  );
}