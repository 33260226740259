import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";




function ResetPassword() {
	const {setAuth, persist, setPersist} = useAuth();

	const errRef = useRef();

	const navigate = useNavigate()
	const location = useLocation();
	const from = location.state?.from?.pathname || "/"
    const queryParameters = new URLSearchParams(window.location.search)
    const guid = queryParameters.get("guid")

	const axiosPrivate = useAxiosPrivate();

	const [pwd, setPwd] = useState('')
	const [confirmPwd, setConfirmPwd] = useState('')

	const [errMsg, setErrMsg] = useState('')
	const [errServ, setErrServ] = useState('')


	useEffect(()=> {
        console.log(guid);
		errRef.current.focus()
        checkGuid()
	},[])

    const checkGuid = async () => {
        try {
            const response =await axiosPrivate.post('/checkResetGuid.php',
            {"guid": guid})
			console.log('test');
            console.log(response);
        } catch (error) {
			console.log(error)
            // navigate('/reset');
            // navigate('/reset', {replace : true});
        }

        
    }
	
	useEffect(() => {
	  setErrMsg('')
	}, [pwd])

	const handleSubmit = async e => {
		e.preventDefault();
        if(pwd !== confirmPwd) {
            setErrServ('Les deux champs ne sont pas identiques')
            return
        }
			  try {
				console.log(pwd);
				const response = await axiosPrivate.post('/resetPassword.php',
				{"password": pwd, "guid":guid})
				.then(response => {
					
					return response
				  })

					navigate('/login', {replace : true});
				
			  } catch (error) {
				  if (!error?.response) {
					  setErrServ('Pas de réponse serveur');
				  } 
				else {
					setErrServ('Authentification échouée');
				  }
				  console.log(error.response);
			  }
			
			
			return;
		
		
	}

	
  return (
    <div id="container" className="cls-container">
		{/* <!-- BACKGROUND IMAGE -->
		<!--===================================================--> */}
		<div id="bg-overlay" className="" style={{backgroundImage: "none"}}></div>
		
		
		{/* <!-- LOGIN FORM --> */}
		{/* <!--===================================================--> */}
		<div className="cls-content">
		    <div className="cls-content-sm panel">
		        <div className="panel-body">
		            <div className="mar-ver pad-btm">
		                <h1 className="h3">Réinitialisation</h1>
		                <p>Entrez votre nouveau mot de passe</p>
		            </div>
		            <form onSubmit={handleSubmit}>
						{errServ.length > 0 ? <div className="form-group text-light pad-ver text-center  bg-danger text-bold">
					<p>{errServ}</p>
					</div> :''}
					
		                <div className="form-group">
		                    <input ref={errRef} type="password" value={pwd} onChange={e => setPwd(e.target.value)}  className="form-control" required placeholder="Mot de passe"/>
		                </div>

                        <div className="form-group">
		                    <input type="password" value={confirmPwd} onChange={e => setConfirmPwd(e.target.value)}  className="form-control" required placeholder="Confirmer mot de passe"/>
		                </div>

						
		                <button className="btn btn-primary btn-lg btn-block" type="submit">Valider</button>
		            </form>
		        </div>
		    </div>
		</div>
    </div>
  );
}

export default ResetPassword;
