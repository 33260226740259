import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";



function ResetPasswordLink() {
	const {setAuth, persist, setPersist} = useAuth();
	const userRef = useRef();
	const errRef = useRef();

	const navigate = useNavigate()
	const location = useLocation();
	const from = location.state?.from?.pathname || "/"

	const axiosPrivate = useAxiosPrivate();
	const [user, setUser] = useState('')

	const [errMsg, setErrMsg] = useState('')
	const [errServ, setErrServ] = useState('')


	useEffect(()=> {
		userRef.current.focus()
	},[])
	
	useEffect(() => {
	  setErrMsg('')
	}, [user])

	const handleSubmit = async e => {
		e.preventDefault();
		if (user.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  )) {
			  try {
				
				const response = await axiosPrivate.post('/getResetPasswordLink.php',
				{"email" : user})
				console.log(response);
				
                if (response?.data?.error) {
                    setErrServ(response?.data?.error);
					errRef.current.focus()
                  } else {
					
					navigate('/login', {replace : true});
					
				  }
				
                

			  } catch (error) {
				  if (!error?.response) {
					  setErrServ('Pas de réponse serveur');
				  } 
				  console.log(error.response);
				  errRef.current.focus()
			  }
			
			
			return;
		}
		setErrMsg('Entrez une addresse mail valide')
		
		
	}

	const togglePersist = () => {
		setPersist(prev=> !prev);
	}
	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist])
	
  return (
    <div id="container" className="cls-container">
		{/* <!-- BACKGROUND IMAGE -->
		<!--===================================================--> */}
		<div id="bg-overlay" className="" style={{backgroundImage: "none"}}></div>
		
		
		{/* <!-- LOGIN FORM --> */}
		{/* <!--===================================================--> */}
		<div className="cls-content">
		    <div className="cls-content-sm panel">
		        <div className="panel-body">
		            <div className="mar-ver pad-btm">
		                <h1 className="h3">Reinitialiser votre mot de passe</h1>
		                <p>Entrez votre addresse mail</p>
		            </div>
		            <form onSubmit={handleSubmit}>
						{errServ.length > 0 ? <div ref={errRef} className="form-group text-light pad-ver text-center  bg-danger text-bold">
					<p>{errServ}</p>
					</div> :''}
					
						{errMsg.length > 0 ? <div className="form-group has-error has-feedback">
						<label for="demo-oi-errinput" className="control-label text-semibold">{errMsg}</label>
						<input value={user} onChange={(e) => setUser(e.target.value)} type="text" id="demo-oi-errinput" className="form-control"/>
						<i onClick={e=> setUser('')} className="demo-pli-cross form-control-feedback"></i>
					</div> :
					<div className="form-group">
						<input type="email" value={user} onChange={(e) => setUser(e.target.value)} ref={userRef} className="form-control" placeholder="Email" required autoComplete="off" />
					</div>
					}
						
		                <button className="btn btn-primary btn-lg btn-block" type="submit">Envoyer le lien de réinitialisation</button>
		            </form>
		        </div>
		    </div>
		</div>
    </div>
  );
}

export default ResetPasswordLink;
