import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import CircularProgress from '@mui/material/CircularProgress';
import SelectGeneral from '../../utils/SelectGeneral';



function LinkBuilding() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();



    const [contract, setContract] = useState('-');
    const [unlinkedgrdf, setUnlinkedGrdf] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState('');

    const [buildingId, setBuildingId] = useState(false);
    const [customerId, setCustomerId] = useState(false);


    const [errIdentification, setErrIdentification] = useState('');


    const ignoreDataList = ['longitude','latitude','customer_id','building_number','gui']

    const sendToGRDF = async () => {
        window.location.replace('https://sofit-sso-oidc.grdf.fr/openam/oauth2/realms/externeGrdf/authorize?client_id=efficube_grdf&state='+buildingId+'-'+customerId+'&scope=openid&response_type=code&redirect_uri=https://api.efficube.com/rest-api-auth/api/callback.php&login_hint=Prenom;Nom;email@gmail.com;Efficube');
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let error = false

        if (error) {
            return
        }
        try {
            // console.log(contract);
            const response = await axiosPrivate.post('/linkContractToBuilding.php', {
                building_id : buildingId,
                pce : contract
            }).catch(e => {
                console.log(e);
            })
            console.log(response);
            setSuccess(true);
            // setbuildingData(response?.data?.data);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        if (success === true) {
            setTimeout(() => {
                navigate('/buildings')
            }, 3000);
            
        }
    
      
    }, [success])

    const getGuiData = async () => {
        const response = await axiosPrivate.post('/getEmptyPceByCustomer.php', {
        }).catch(e => {
            console.log(e);
        })
        console.log(response);
        setLoading(false);
        let tempArray =[];
        response?.data?.data.forEach(contract => {
            tempArray.push({
                id : contract.id,
                value : contract.pce
            })
        });
        setUnlinkedGrdf(tempArray)
    }
    
    useEffect(() => {
        if (location?.state?.state === null || location?.state?.state === undefined) {
            navigate('/buildings');
            return
        }
        console.log(location.state.state);
        getGuiData()
        for (const [fieldName,fieldValue] of Object.entries(location.state.state)) {
            if (fieldName === 'id') {
                setBuildingId(fieldValue);
            }
            else if (fieldName === 'customer_id') {
                setCustomerId(fieldValue);
            }
        }
    },[])
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface batiments</h3>
                <p>Récupérez vos données grdf en liant votre batiment</p>
            </div>
        </div>

        <div id="page-content">
            <div className="row">
            
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    
                    <div id="demo-panel-network" className="panel">
                        <div className="panel-heading">
                            <div className="panel-control">
                            </div>
                            <h2 className="panel-title">Lier votre batiment</h2>
                        </div>

                        <div className="panel-body">
                            {loading ? 
                            <div className="col-lg-8 mar-btm pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
                                <div style={{ display: 'inline-block' }} className="">
                                    <CircularProgress />
                                </div>
                            </div> 
                            : null}
                            {loading ? null : unlinkedgrdf.length > 0 ? 
                            <div className="form-group">
                                <label htmlFor="form_title" className="required">N° GI ou PCE</label>
                                <SelectGeneral value={contract} setter={setContract} valueList={unlinkedgrdf} generalLabel = 'Selectionnez votre GUI ou PCE'/>
                            </div> :
                            <div className=" pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
                                <button onClick={() => sendToGRDF()} class="btn btn-lg btn-primary mainnav-toggle">Ajouter un contract GRDF</button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>

            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mar-btm pad-ver " role="group" aria-label="Basic example">
                    {unlinkedgrdf.length > 0 ? contract === '-' ?<button disabled className="btn btn-success">Lier</button> : <button onClick={(e) =>{ handleSubmit(e)}} className="btn btn-success">Lier</button> : null}
                    <NavLink className="btn btn-primary " to="../../buildings">Retour</NavLink>
                </div>
                <div className="col-lg-2"></div>
            </div>

            {loading ? null : unlinkedgrdf.length > 0 ?
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mar-btm pad-ver " role="group" aria-label="Basic example">
                    <div className="panel-heading text-md-center text-xs-center text-sm-center text-lg-center">
                        <div className="panel-control">
                        </div>
                        <h2 className="panel-title">Aucun des GUI ou PCE présents ne correspondent à ce batiment ?</h2>
                    </div>
                    <div className="pad-ver text-md-center text-center text-xs-center text-sm-center text-lg-center">
                        <button onClick={() => sendToGRDF()} class="btn btn-lg btn-primary mainnav-toggle">Ajouter un contract GRDF</button>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
            : null}
            
        </div>
    </div>
  );
}

export default LinkBuilding;
