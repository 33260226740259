import axios from "axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth, auth } = useAuth()


    const refresh = async () => {

        // const response = await axios.get('http://localhost/rest-api-auth/api/refresh_token.php', {
            const response = await axios.get('https://api.efficube.com/rest-api-auth/api/refresh_token.php', {
            withCredentials: true
        })
        if (auth.logout !== true) {
            setAuth(prev => {
                return {
                    ...prev,
                    username: response.data.user,
                    user: response.data.id,
                    roles: response.data.roles,
                    accessToken: response.data.token
                }
            })
        }

        return response.data.token
    }
    return refresh;
}

export default useRefreshToken;