import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const PersistLogin = () => {
    const [isLoading,setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {auth, persist} = useAuth();

    useEffect(()=> {
        const verifyRefreshToken = async() => {
            try {
                await refresh();
            } catch (error) {
                console.error(error)
            } finally{
               setIsLoading(false); 
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    },[])

    useEffect(() => {

    }, [isLoading])

    return(
        <>
         {!persist ?
         <Outlet/>
         :
         isLoading 
         ? 
         <div id="page-head">
            <div className="col-sm-6 col-md-4 col-lg-3">
					            <div className="panel">
					                <div className="panel-body">
					                    <div className="load6">
					                        <div className="loader"></div>
					                    </div>
					                </div>
					            </div>
					        </div>
        </div>
        : 
        <Outlet/>
        // :
        // <div id="page-head">
        //     <div class="col-sm-6 col-md-4 col-lg-3">
		// 			            <div class="panel">
		// 			                <div class="panel-body">
		// 			                    <div class="load6">
		// 			                        <div class="loader"></div>
		// 			                    </div>
		// 			                </div>
		// 			            </div>
		// 			        </div>
        // </div>
        }
        
        </>
    )
}

export default PersistLogin;