import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import SelectAutoWidth from "../../utils/Select";
import SelectGeneral from '../../utils/SelectGeneral';
import '../style/customStyle.css'



Date.prototype.ddmmyyyy = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
            (dd>9 ? '' : '0') + dd,
            (mm>9 ? '' : '0') + mm,
            this.getFullYear()
           ].join('/');
  };
function ListDevicesAdmin() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();

    
    const [batimentMapping, setBatimentMapping] = useState([])
    const [batiments, setBatiments] = useState([])
    const [axiosData, setAxiosData] = useState(null)
    const [devicesData, setDevicesData] = useState([])
    const [pagination, setPagination] = useState(1)
    const [filter, setFilter] = useState('-')
    const [customerData, setCustomerData] = useState([])
    const [customerFilter, setCustomerFilter] = useState('-');
    const [deviceTypeMapping, setDeviceTypeMapping] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);


    const getDeviceTypeMapping = async () => {
        try {
            const response = await axiosPrivate.post('/getDevicesTypesList.php').catch(e => {
                console.log(e.response)
            })
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer[element.id] = element.name
            });
            setDeviceTypeMapping(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/adminGetCustomer.php').catch(e => {
                console.log(e.response)
            })
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer.push({'id': element.id, 'value': element.name})
            });
            setCustomerData(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }


    const search = (key, inputArray) => {
        for (let i=0; i < inputArray.length; i++) {
            if (inputArray[i].name === key) {
                return `${inputArray[i].id}`;
            }
        }
      }

    const buildDataTable = () => {
        if (axiosData === null) {
            return
        }
        let temporaryDeviceData =[];
        for (const [buildingName, buildingData] of Object.entries(axiosData)) {
            let buildingTitle = buildingData?.title
            // console.log(buildingData);
            if (buildingData?.device_array.length === 0 || (filter !== '-' && filter !== buildingTitle)) {
                continue;
            }
            
            for (const [deviceType, device] of Object.entries(buildingData.device_array)) {
                console.log(device);
                    temporaryDeviceData.push({
                        'id': device?.id,
                        'building': buildingTitle,
                        'building_id': device?.building_id,
                        'name': device?.name,
                        'description': device?.description,
                        'status': device?.status,
                        'type': device?.device_type_id,
                        'position': device?.position,
                        'actif_since': new Date(device?.actif_since.date).ddmmyyyy(),
                        'constructor': device?.constructor,
                        'model': device?.model,
                        'serial_num': device?.serial_num,
                        'installed_since': new Date(device?.installed_since.date).ddmmyyyy(),
                        'navigator_name': device?.navigator_name,
                        'topic': device?.topic,
                        'scale': device?.scale,
                        'mqtt_mapping_id':device?.mqtt_mapping_id
                    })
                
            }
        }
        setDevicesData(temporaryDeviceData)
    }

    const getBuildingData = async () => {
        try {
            
            // console.log(response?.data?.data);
            const response = await axiosPrivate.post('/getDevicesListAdmin.php', {
                'customer_id': customerFilter
            })
            console.log(response?.data);
            let temporaryBatiment = [];
            let temporaryBatimentMapping = [];
            for (const [index, buildingData] of Object.entries(response.data?.data)) {
                temporaryBatiment.push(buildingData.title)
                temporaryBatimentMapping.push({
                    name: buildingData.title,
                    id: buildingData?.id
                })
            }
            
            setBatimentMapping(temporaryBatimentMapping);
            setBatiments(temporaryBatiment);
            setAxiosData(response?.data?.data);
            
            
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }


    const toggleDeviceStatus = async (index) => {
        let pageAdd = ((pagination-1)*20) > 0 ? ((pagination-1)*20) : 0;
        index = index + pageAdd
        console.log(index);
        if (devicesData.length <= 0) {
            return
        }
        let temporaryDevicesData = devicesData;
        
        temporaryDevicesData[index].status = temporaryDevicesData[index].status ? false : true;
        console.log(temporaryDevicesData[index].id, search(temporaryDevicesData[index].building, batimentMapping));
        const response = await axiosPrivate.post('/toggleDeviceStatus.php', {
            id: temporaryDevicesData[index].id,
            building: search(temporaryDevicesData[index].building, batimentMapping)
        })

        
        setDevicesData(temporaryDevicesData);
    }

    const deleteDevice = async () => {
        let index = deleteIndex;
        let pageAdd = ((pagination-1)*20) > 0 ? ((pagination-1)*20) : 0;
        index = index + pageAdd
        console.log(index);
        if (devicesData.length <= 0) {
            return
        }
        let temporaryDevicesData = devicesData;
        console.log(temporaryDevicesData);
        console.log(index);
        
        // temporaryDevicesData[index].status = temporaryDevicesData[index].status ? false : true;
        const id = temporaryDevicesData[index].id;
        
        temporaryDevicesData.splice(index, 1);
        console.log(temporaryDevicesData);
        const response = await axiosPrivate.post('/deleteDevice.php', {
            id: id
        })
        console.log(response);
        setDeleteIndex(null)
        setConfirmDelete(false)
        setDevicesData(temporaryDevicesData);
    }

    const toggleConfirm = (index) => {
        setConfirmDelete(true);
        setDeleteIndex(index);
    }

    useEffect(() => {
        if (customerFilter !== '-') {
            getBuildingData()
            setFilter('-')
            setConfirmDelete(false);
            setDeleteIndex(null)
        }
        
    
      
    }, [customerFilter])

    useEffect(() => {
        setConfirmDelete(false);
        setDeleteIndex(null)
    }, [pagination])

    useEffect(() => {
        getCustomerData()
        getDeviceTypeMapping()
    
      
    }, [])

    useEffect(() => {
        buildDataTable()
    },[axiosData, filter])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface appareils</h3>
                <p>Visualisez tous vos appareils et leurs informations</p>
            </div>
        </div>

        <div id="page-content" >

                                <div className="row">
                                        <div className="col-lg-12">

                                                {/* <!--Network Line Chart-->
                                                <!--===================================================--> */}
                                                <div id="demo-panel-network" className="panel">
                                                        <div className="panel-heading">
                                                                <h3 className="panel-title">Liste des appareils</h3>
                                                        </div>
                                                        <div className='pad-all'>
                                                            <SelectGeneral valueList={customerData} value={customerFilter} setter={setCustomerFilter} label='Filtre client' generalLabel="Selectionnez le client"/>
                                                        </div>
                                                        <div className='pad-all'>
                                                        <SelectAutoWidth  batiments={batiments} filter={filter} setFilter={setFilter}/>
                                                        </div>

                                                        {confirmDelete === true ? 
                                                        <div className="col-lg-12">
                                                        <div className="col-lg-4"></div>
                                                        <div className="col-lg-4 confirm-area mar-btm"><p>Voulez vous vraiment supprimer l'appareils ?</p>
                                                        <div className='confirm-button'>
                                                        <button className='btn btn-danger' onClick={() => deleteDevice()}>Supprimer</button> <button onClick={() => {setConfirmDelete(false); setDeleteIndex(null)}} className='btn btn-primary'>Annuler</button></div>
                                                        </div> 
                                                        <div className="col-lg-4"></div>
                                                    </div>
                                                    :
                                                    null}
                                                        
                                                        
                                                        
                                                        <table className="table dataTable  table-striped">
                                                                <thead>
                                                                    
                                                                <tr>
                                                                        <th>Nom</th>
                                                                        <th>Description</th>
                                                                        <th>Batiment</th>
                                                                        <th>Position</th>
                                                                        <th>Status</th>
                                                                        <th>Type</th>
                                                                        <th>Fabricant</th>
                                                                        <th>Model</th>
                                                                        <th>Numero série</th>
                                                                        <th>Actif depuis</th>
                                                                        <th>Installé le</th>
                                                                        {['admin','manager'].includes(auth?.roles) ? 
                                                                        <th>Actions</th>
                                                                        :
                                                                        null}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {devicesData.length > 0 ?
                                                                    devicesData.slice((pagination-1)*20,(pagination)*20).map((device,index) => 
                                                                    <tr>
                                                                        <td>{device?.name}</td>
                                                                        <td >{device?.description}</td>
                                                                        <td >{device?.building}</td>
                                                                        <td >{device?.position}</td>
                                                                        <td >{device?.status  ? 'Actif' : 'Inactif'}</td>
                                                                        <td >{deviceTypeMapping[device?.type]}</td>
                                                                        <td >{device?.constructor}</td>
                                                                        <td >{device?.model}</td>
                                                                        <td >{device?.serial_num}</td>
                                                                        <td >{device?.actif_since}</td>
                                                                        <td >{device?.installed_since}</td>
                                                                        {['admin','manager'].includes(auth?.roles) ? 
                                                                        <td >
                                                                            <Link
                                                                            to={"modify"}
                                                                            state={{state: {...device, building: search(device?.building, batimentMapping)}, buildingMapping : batimentMapping}}
                                                                            >
                                                                            Modifier
                                                                            </Link>

                                                                            <span> </span>

                                                                            <a
                                                                            className={device?.status ? 'text-danger' : 'text-success'  }
                                                                            href='#'
                                                                            onClick={() => toggleDeviceStatus(index)}
                                                                            >
                                                                            {device?.status ?  'Desactiver' : 'Activer'  }
                                                                            </a>

                                                                            <span> </span>

                                                                            <a
                                                                            className={'text-danger'}
                                                                            href='#'
                                                                            onClick={(e) => toggleConfirm(index)}
                                                                            >
                                                                            Supprimer
                                                                            </a>
                                                                            </td>
                                                                        :
                                                                        null
                                                                        }
                                                                    </tr>
                                                                    
                                                                    )
                                                                    :
                                                                    <tr>
                                                                        <td className='text-bold text-lg-center bg-primary' colSpan="13">Aucun appareil</td>
                                                                    </tr>
                                                                    }
                                                                </tbody>
                                                        </table>
                                                        <div className='pad-ver'>
                                                            {devicesData.length > 20 ?
                                                            <Pagination count={Math.ceil(devicesData.length/20)} onChange={(e,page) => setPagination(page)} color="primary" />
                                                            :
                                                            null}
                                                        </div>
                                                </div>
                                                
                                                
                                        </div>
                                </div>


                        </div>
    </div>
  );
}

export default ListDevicesAdmin;
