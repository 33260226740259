import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useRefreshToken from "../../hooks/useRefreshToken"
import BasicDatePicker from "../utils/Datepicker";
import SelectAutoWidth from "../utils/Select";
import SelectWithouthDefault from "../utils/SelectWithouthDefault";
import Highcharts from 'highcharts'
import { getDate, subDays, subHours } from 'date-fns'
import moment from 'moment';
import 'moment-timezone';



import HighchartsReact from 'highcharts-react-official'
import CircularProgress from '@mui/material/CircularProgress';
import { height, positions, width } from "@mui/system";
import axios from "../../api/axios";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);


window.moment = moment;
Date.prototype.ddmmyyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
// Highcharts.dateFormat("Month: %m Day: %d Year: %Y", 20, false);
Highcharts.setOptions({
    time: {
        timezone: 'Europe/Paris'
    },
    lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
            'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        decimalPoint: ",",
        downloadPNG: "Télécharger en image PNG",
        downloadJPEG: "Télécharger en image JPEG",
        downloadPDF: "Télécharger en document PDF",
        downloadSVG: "Télécharger en document Vectoriel",
        downloadCSV: "Télecharger en document CSV",
        downloadXLS: 'Télécharger en document XLS',
        exportButtonTitle: "Export du graphique",
        loading: "Chargement en cours...",
        printButtonTitle: "Imprimer le graphique",
        resetZoom: "Réinitialiser le zoom",
        resetZoomTitle: "Réinitialiser le zoom au niveau 1:1",
        thousandsSep: " ",
        printChart: "Imprimer le graphique",
        viewFullscreen: "Agrandir à la taille de l'écran"
    }
});

{/* <HighchartsReact
    highcharts={Highcharts}
    options={options}
/> */}









const Dashboard = () => {
    const refresh = useRefreshToken();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();
    const [filter, setFilter] = useState('-')
    const [batiments, setBatiments] = useState([])
    const [devices, setDevices] = useState(0)
    const [actifDevices, setActifDevices] = useState(0)
    const [actifDevicesHum, setActifDevicesHum] = useState(0)
    const [actifDevicesTemp, setActifDevicesTemp] = useState(0)
    const [actifDevicesConsDef, setActifDevicesConsDef] = useState(0)
    const [from, setFrom] = useState(subDays(new Date(), 7));
    // const [from, setFrom] = useState(subDays(new Date(), 37));
    const [to, setTo] = useState(new Date());
    // const [to, setTo] =  useState(subDays(new Date(), 30));
    const [influxData, setInfluxData] = useState(null);
    const [optionTemp, setOptionTemp] = useState(null);
    const [optionHum, setOptionHum] = useState(null);
    const [hasTemp, setHasTemp] = useState(false);
    const [hasHum, setHasHum] = useState(false);
    const [meanTemp, setMeanTemp] = useState(false);
    const [defaultArray, setDefaultArray] = useState([]);
    const [consigneArray, setConsigneArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [interval, setInterval] = useState(15);

    const controller = new AbortController();
    const intervalList = [
        { 'id': 10, 'name': '10 minutes' },
        { 'id': 15, 'name': '15 minutes' },
        { 'id': 30, 'name': '30 minutes' },
        { 'id': 60, 'name': '1 heure' },
        { 'id': 240, 'name': '4 heures' },
        { 'id': 720, 'name': '12 heures' },
        { 'id': 1440, 'name': '1 jour' }
    ]


    const getInfluxData = async (fromTime = '168h', toTime = '0h') => {
        try {
            const response = await axiosPrivate.post('/getDashboard.php', {
                // const response = await axiosPrivate.post('/getDashboardData.php', {
                'from': fromTime,
                'to': toTime,
                'interval': interval,
            })
            console.log(response);
            let responseData = response.data?.data;
            return (responseData)
            // setBatiments(Object.keys(responseData));
            // setInfluxData(responseData);

        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login', { state: { from: location }, replace: true })
        }

    }


    useEffect(() => {
        let iscanceled = false
        setIsLoading(true);
        const handleChange = async () => {
            if (from !== null && to !== null && interval !== null) {

                let datefrom = new Date(from).toISOString().slice(0, 10);
                let dateTo = new Date(to)
                if (dateTo.toISOString().slice(0, 10) == new Date().toISOString().slice(0, 10)) {
                    dateTo.setMinutes(Math.floor(parseInt(dateTo.getMinutes())/10) * 10)
                    let minutes = parseInt(dateTo.getMinutes()) === 0 ? '00' : dateTo.getMinutes();
                    let hour = dateTo.getHours() < 10 ? '0'+dateTo.getHours() : dateTo.getHours();
                    dateTo = dateTo.toISOString().slice(0, 10)+' ' +dateTo.getHours()+':'+minutes+':00'
                } else {
                    dateTo.setDate(dateTo.getDate + 1)
                    dateTo = new Date(to).toISOString().slice(0, 10);
                }
                console.log(dateTo);
                let axiosData = await getInfluxData(datefrom, dateTo);
                if (!iscanceled) {
                    let batimentArray = [];
                    for (let index = 0; index < axiosData.length; index++) {
                        batimentArray.push(axiosData[index].title);

                    }
                    // console.log(batimentArray);
                    setBatiments(batimentArray)
                    setInfluxData(axiosData)
                    setIsLoading(false)
                }
            }
        }

        handleChange();


        return () => {
            iscanceled = true;
        }
        // console.log(to);
    }, [from, to, interval])

    const chart = useRef();

    const getDefinitionAndDefault = () => {
        if (influxData === null) {
            return
        }

        let tempDefault = [];
        let tempConsigne = [];
        let activeDevices = 0;
        let emptyDevices = 0;

        for (let indexBuilding = 0; indexBuilding < influxData.length; indexBuilding++) {
            const buildingData = influxData[indexBuilding];
            let buildingTitle = buildingData.title
            // console.log(buildingData);

            for (let indexDevice = 0; indexDevice < buildingData.device_array.length; indexDevice++) {
                const device = buildingData.device_array[indexDevice];
                emptyDevices++;
                if (device.device_type_id !== 5 && device.device_type_id !== 6 || device.data_array.length === 0) {
                    continue
                }

                activeDevices++;
                let deviceName = `${buildingTitle}-${device.name}`
                let deviceValue = device.data_array.pop().value;

                //si device égale consigne
                if (device.device_type_id === 5) {
                    tempConsigne.push({ name: deviceName, value: deviceValue })
                } else if (device.device_type_id === 6) {
                    tempDefault.push({ name: deviceName, value: deviceValue })
                }

            }
        }

        setDefaultArray(tempDefault)
        setConsigneArray(tempConsigne)
        setDevices(emptyDevices);
        
        setActifDevicesConsDef(activeDevices);
    }

    const createTempChart = () => {
        if (influxData === null) {
            return
        }

        let series = [];
        let temporaryMeanTemp = [];
        let deviceCounter = 0;
        let activeDevices = 0;
        let temporaryHasTemp =false;

        for (let indexBuilding = 0; indexBuilding < influxData.length; indexBuilding++) {
            const buildingData = influxData[indexBuilding];
            if (filter !== '-' && filter !== buildingData.title) {
                continue
            }
            // const buildingData = influxData[indexBuilding];
            let buildingTitle = buildingData.title
            // console.log(buildingData);

            for (let indexDevice = 0; indexDevice < buildingData.device_array.length; indexDevice++) {
                const device = buildingData.device_array[indexDevice];
                if (device.device_type_id !== 2 || device.data_array.length === 0) {
                    continue
                }
                if (temporaryHasTemp === false) {
                    temporaryHasTemp = true;
                }
                deviceCounter++;
                let serieName = `${buildingTitle}-${device.name}`
                let serieData = [];

                temporaryMeanTemp.push({ name: serieName, mean: device.mean })
                for (let indexData = 0; indexData < device.data_array.length; indexData++) {
                    const data = device.data_array[indexData];
                    serieData.push([data.date_timestamp, data.value])
                }
                series.push({
                    name: serieName,
                    data: serieData
                })
                activeDevices++
            }

        }
        setHasTemp(temporaryHasTemp);
        temporaryMeanTemp.sort((a, b) => b.mean - a.mean);
        setActifDevicesTemp(activeDevices);
        setMeanTemp(temporaryMeanTemp)
        let option = {
            chart: {
                zoomType: 'x',
                // marginBottom: actifDevices/2 * 25 + 50 
            },
            title: {
                text: null
            },

            yAxis: {
                title: {
                    text: 'Température en °C'
                }
            },

            credits: {
                enabled: false
            },

            xAxis: {
                accessibility: {
                    rangeDescription: 'Période'
                },
                dateTimeLabelFormats: {
                    hour: '%H:%M'
                },
                tickInterval: 10800000,
                type: 'datetime',
            },

            series: series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },

            exporting: {
                buttons: {
                   contextButton: {
                    menuItems: [
                        'downloadCSV','downloadXLS'
                     ]
                   },
                }
             },
        }
        return option
    }

    const createHumChart = () => {
        if (influxData === null) {
            return
        }

        let series = [];
        let temporaryMeanTemp = [];
        let deviceCounter = 0;
        let activeDevices = 0;
        let temporaryHasHum = false

        for (let indexBuilding = 0; indexBuilding < influxData.length; indexBuilding++) {
            const buildingData = influxData[indexBuilding];
            let buildingTitle = buildingData.title
            // console.log(buildingData);
            if (filter !== '-' && filter !== buildingData.title) {
                continue
            }

            for (let indexDevice = 0; indexDevice < buildingData.device_array.length; indexDevice++) {
                const device = buildingData.device_array[indexDevice];
                if (device.device_type_id !== 92 || device.data_array.length === 0) {
                    continue
                }
                if (temporaryHasHum === false) {
                    temporaryHasHum = true;
                }
                deviceCounter++;
                let serieName = `${buildingTitle}-${device.name}`
                let serieData = [];
                for (let indexData = 0; indexData < device.data_array.length; indexData++) {
                    const data = device.data_array[indexData];
                    serieData.push([data.date_timestamp, data.value])
                }
                series.push({
                    name: serieName,
                    data: serieData
                })

                activeDevices++;
            }
        }
        setHasHum(temporaryHasHum)
        setActifDevicesHum(activeDevices);
        let option = {
            chart: {
                zoomType: 'x',
                // marginBottom: actifDevices/2 * 25 + 50 
            },
            title: {
                text: null
            },

            yAxis: {
                title: {
                    text: 'Consommation en m3'
                }
            },

            credits: {
                enabled: false
            },

            xAxis: {
                accessibility: {
                    rangeDescription: 'Période'
                },
                dateTimeLabelFormats: {
                    hour: '%H:%M'
                },
                tickInterval: 10800000,
                type: 'datetime',
            },

            series: series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },

            exporting: {
                buttons: {
                   contextButton: {
                    menuItems: [
                        'downloadCSV','downloadXLS'
                     ]
                   },
                }
             },
        }
        return option
    }

    useEffect(() => {

    }, [])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [actifDevices, devices])

    useEffect(() => {
        setActifDevices(actifDevicesTemp + actifDevicesHum + actifDevicesConsDef);
        

    }, [actifDevicesHum, actifDevicesTemp, actifDevicesConsDef])

    useEffect(() => {
        setOptionTemp(createTempChart());
        setOptionHum(createHumChart());
        getDefinitionAndDefault();
        window.dispatchEvent(new Event('resize'))

    }, [influxData, filter])

    useEffect(() => {
        if (chart && chart.current && chart.current.chart) {
            // chart.current.chart.downloadCSV();
            const container = chart.current.container.current;

            container.style.height = "100%";
            container.style.width = "100%";
            chart.current.chart.reflow();
        }

    }, [optionTemp, optionHum])

    return (

        <div id="content-container">
            <div id="page-head">
                <div className="pad-all text-center">
                    <h3>Bienvenue sur votre Dashboard Effivision</h3>
                    <p>Descendez pour visualiser vos données et statistiques énergétiques
                    </p></div>
            </div>

            <div id="page-content">

                <div className="col-md-4">
                    <div className="panel panel-info panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-building icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{filter !== '-' ? 1 : batiments.length}</p>
                            <p className="mar-no">Bâtiments</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-mint panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-router icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{devices}</p>
                            <p className="mar-no">Appareils</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-danger panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-router-2 icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{actifDevices}</p>
                            <p className="mar-no">Appareils actif</p>
                        </div>
                    </div>
                </div>
                {isLoading ? <div className="col-lg-12 mar-btm pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
                    <div style={{ display: 'inline-block' }} className="">
                        <CircularProgress />
                    </div>
                </div>
                    :
                    null
                }

                
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="col-lg-6">
                                <div className="fixed-fluid mar-rgt">
                                    <div className="pull-sm-left d-inline mar-btm">
                                        <SelectAutoWidth batiments={batiments} filter={filter} setFilter={setFilter} />
                                        {/* <SelectWithouthDefault batiment={interval} setBatiment={setInterval} batimentList={intervalList} label='Interval données' /> */}

                                    </div>
                                    <div className="pull-sm-left d-inline mar-btm">
                                        <SelectWithouthDefault batiment={interval} setBatiment={setInterval} batimentList={intervalList} label='Interval données' />

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="fixed-fluid mar-btm">
                                    <div className="fixed-sm-250 pull-sm-right">
                                        <div id="demo-dp-range">
                                            <div className="input-daterange input-group" id="datepicker">
                                                <BasicDatePicker label='Date Début' main='from' to={to} from={from} setFrom={setFrom} />
                                                <span className="input-group-addon">to</span>
                                                <BasicDatePicker label='Date Fin' main='to' to={to} from={from} setTo={setTo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>

                {hasHum && hasTemp ?
                    <div className="row">
                    <div className="row">
                    <div className="col-lg-12">
                        <div id="demo-panel-network" className="panel">
                            <div className="panel-heading">
                                <div className="panel-control">
                                    <button id="demo-panel-network-refresh" className="btn btn-default btn-active-primary" data-toggle="panel-overlay" data-target="#demo-panel-network"><i className="demo-psi-repeat-2"></i></button>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle btn btn-default btn-active-primary" data-toggle="dropdown" aria-expanded="false"><i className="demo-psi-dot-vertical"></i></button>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a onClick={() => { setFrom(subHours(new Date(), 12)); setTo(new Date()) }}>12h</a></li>
                                            <li><a onClick={() => { setFrom(subDays(new Date(), 1)); setTo(new Date()) }}>24h</a></li>
                                            <li><a onClick={() => { setFrom(subDays(new Date(), 7)); setTo(new Date()) }}>Une semaine</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className="panel-title">Température</h3>
                            </div>


                            {/* <!--chart placeholder--> */}
                            <div className="pad-all">
                                <div id="highchartsTemp"
                                style={ actifDevices < 10 ? {height: `${500 + actifDevices * 10}px`, width: '100%'} : null }
                                >
                                    <HighchartsReact
                                        containerProps={{ style: { width: "100%" } }}
                                        ref={chart}
                                        highcharts={Highcharts}
                                        options={optionTemp}
                                    />
                                </div>

                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-12">
                            <div id="demo-panel-network" className="panel">
                                <div className="panel-heading">
                                    <div className="panel-control">
                                        <button id="demo-panel-network-refresh" className="btn btn-default btn-active-primary" data-toggle="panel-overlay" data-target="#demo-panel-network"><i className="demo-psi-repeat-2"></i></button>
                                        <div className="dropdown">
                                            <button className="dropdown-toggle btn btn-default btn-active-primary" data-toggle="dropdown" aria-expanded="false"><i className="demo-psi-dot-vertical"></i></button>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><a onClick={() => { setFrom(subHours(new Date(), 12)); setTo(new Date()) }}>12h</a></li>
                                                <li><a onClick={() => { setFrom(subDays(new Date(), 1)); setTo(new Date()) }}>24h</a></li>
                                                <li><a onClick={() => { setFrom(subDays(new Date(), 7)); setTo(new Date()) }}>Une semaine</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h3 className="panel-title">Consommation</h3>
                                </div>


                                {/* <!--chart placeholder--> */}
                                <div className="pad-all">
                                    <div id="highchartsTemp"
                                    style={ actifDevices < 10 ? {height: `${500 + actifDevices * 10}px`, width: '100%'} : null }
                                    >
                                        <HighchartsReact
                                            containerProps={{ style: { width: "100%" } }}
                                            ref={chart}
                                            highcharts={Highcharts}
                                            options={optionHum}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        

                        
                </div>
                    :
                    hasHum || hasTemp ?

                        <div className="row">
                            <div className="col-lg-12">

                                <div id="demo-panel-network" className="panel">
                                    <div className="panel-heading">
                                        <div className="panel-control">
                                            <button id="demo-panel-network-refresh" className="btn btn-default btn-active-primary" data-toggle="panel-overlay" data-target="#demo-panel-network"><i className="demo-psi-repeat-2"></i></button>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle btn btn-default btn-active-primary" data-toggle="dropdown" aria-expanded="false"><i className="demo-psi-dot-vertical"></i></button>
                                                <ul className="dropdown-menu dropdown-menu-right">
                                                    <li><a onClick={() => { setFrom(subHours(new Date(), 12)); setTo(new Date()) }}>12h</a></li>
                                                    <li><a onClick={() => { setFrom(subDays(new Date(), 1)); setTo(new Date()) }}>24h</a></li>
                                                    <li><a onClick={() => { setFrom(subDays(new Date(), 7)); setTo(new Date()) }}>Une semaine</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h3 className="panel-title">Température</h3>
                                    </div>


                                    {/* <!--chart placeholder--> */}
                                    <div className="pad-all">
                                        <div id="highchartsTemp" style={ actifDevices < 10 ? {height: `${20}vh`, width: '100%'} : {height: `${20 +1*actifDevices}vh`, width: '100%'} }>
                                            <HighchartsReact
                                                containerProps={{ style: { width: "100%" } }}
                                                ref={chart}
                                                highcharts={Highcharts}
                                                options={optionTemp}
                                            />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        :
                        null
                }

                


                <div className="panel-group accordion" id="demo-acc-info-outline">

                    {hasTemp === true ?
                        <div className="panel panel-bordered panel-info">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-parent="#demo-acc-info-outline" data-toggle="collapse" href="#demo-acd-info-outline-1" aria-expanded="false" className="collapsed">Températures moyennes du {from.ddmmyyyy()} au {to.ddmmyyyy()}</a>
                                </h4>
                            </div>
                            <div className="panel-collapse collapse" id="demo-acd-info-outline-1" aria-expanded="false" style={{ height: '0px' }}>
                                <div className="panel-body">
                                    {meanTemp !== false ?
                                        meanTemp.map((mean, index) => <p key={index}>{mean?.name} : {mean?.mean}°C</p>)
                                        :
                                        null}
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {consigneArray.length > 0 || defaultArray.length > 0 ?
                        <div className="panel panel-bordered panel-info">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-parent="#demo-acc-info-outline" data-toggle="collapse" href="#demo-acd-info-outline-3" aria-expanded="false" className="collapsed">Consignes et Défauts</a>
                                </h4>
                            </div>
                            <div className="panel-collapse collapse" id="demo-acd-info-outline-3" aria-expanded="false" style={{ height: '0px' }}>
                                <div className="panel-body">
                                    <div>
                                        {consigneArray.length > 0 ?
                                            consigneArray.map((consigne, index) => <p key={index}>{consigne?.name} : {consigne?.value}°C</p>)
                                            : null
                                        }
                                        {defaultArray.length > 0 ?
                                            defaultArray.map((def, index) => <p key={index}>{def?.name} : <span className={def?.value === 'Ok' ? 'text-success' : 'text-danger'}>{def?.value}</span></p>)
                                            : null
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                        :
                        null
                    }


                </div>

            </div>
        </div>
    )
}

export default Dashboard