import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import getValueFromPath from '../../../utils/getValueFromPath';


function ModifyCustomerDirectus() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();

    const [mapping, setMapping] = useState('');
    const clientDataMapping = {
        "Nom du client" : 'name',
        "Nom social" : 'entity_fields.social_name',
        "Siret/RNA" : 'entity_fields.uuid',
        'Adresse' : 'address',
    } 
    const typeMapping = [
        {
            'type' : 'string',
            'max' : null,
            'min' : 1,
            'check' : null
        },
        {
            'type' : 'string',
            'max' : null,
            'min' : 1,
            'check' : null
        },
        {
            'type' : 'string',
            'max' : null,
            'min' : 1,
            'check' : null
        },
        {
            'type' : 'string',
            'max' : null,
            'min' : 1,
            'check' : null
        },
    ]

    const [values, setValues] = useState([]);
    const [formError, setFormError] = useState([]);
    const [id, setId] = useState(null);

    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (success === true) {
            return
        }
        let error = false
        let tempError = [...formError];
        console.log(values);
        let indexCount = 0;
        let formData = {
            id: id
        };

        for (const [fieldName,fieldValue] of Object.entries(clientDataMapping)) {
            const value = values[indexCount];
            if (typeMapping[indexCount].type === 'string') {
                if (typeMapping[indexCount].max !== null && typeMapping[indexCount].max < value.length) {
                    error = true
                    tempError[indexCount] = `Le champ doit etre inférieur à ${typeMapping[indexCount].max} charactères`
                }

                if (typeMapping[indexCount].min !== null && typeMapping[indexCount].min > value.length) {
                    error = true
                    if (typeMapping[indexCount].min === 1) {
                        tempError[indexCount] = `Le champ doit etre renseigné`
                    } else {
                        tempError[indexCount] = `Le champ doit contenir au minimum ${typeMapping[indexCount].min} charactères`
                    }
                    
                }

                formData = {
                    ...formData,
                    [fieldValue]: value
                }
            }
            indexCount++;
        }


        console.log(formData)
        if (error === true) {
            setFormError(tempError)
            return
        }

        try {
            const response = await axiosPrivate.post('/directus_routes/entities/update.php',formData).catch(e => {
                console.log(e);
            })
            console.log(response);
            setSuccess(true);
            // setbuildingData(response?.data?.data);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        if (success === true) {
            setTimeout(() => {
                navigate('/customers')
            }, 3000);
            
        }
    
      
    }, [success])


    
    useEffect(() => {
        if (location?.state?.state === null || location?.state?.state === undefined) {
            navigate('/customers');
            return
        }
        const customerData = location?.state?.state;
        setId(customerData?.id)
        let valueTemp = [];
        let errorTemp = [];
        let indexCount = 0;
        for (const [fieldName,fieldValue] of Object.entries(clientDataMapping)) {
            errorTemp.push('');
            valueTemp.push(getValueFromPath(customerData,fieldValue))
            indexCount++;
        }
        setFormError(errorTemp)
        setValues(valueTemp);
        
    },[])
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface client</h3>
                <p>Modifier le client</p>
            </div>
        </div>

        <div id="page-content">
            <div className="row">
            
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    {success ? 
                    <div class="alert alert-success">
                        <strong>Client mis à jour</strong> Vous allez être ramené a l'acceuil
                    </div>
                    : 
                    null
                    }
                    
                    <div id="demo-panel-network" className="panel">
                        <div className="panel-heading">
                            <div className="panel-control">
                            </div>
                            <h2 className="panel-title">Modifier un client</h2>
                        </div>

                        <div className="panel-body">
                            <form className='' name="form" onSubmit={(e) =>{ handleSubmit(e)}} wtx-context="F3729247-E399-4064-9DB8-3F2E6D9DF32F">
                                <div id="form">

                                    {Object.keys(clientDataMapping).map((fieldName,index) => {
                                    
                                            if (typeMapping[index].type === 'string') {
                                                return(
                                                    <div>
                                                        {formError.length > 1 && formError[index].length > 1 ? 
                                                        <div className="alert alert-danger">
                                                        <strong>Erreur : </strong> {formError[index]}
                                                    </div>
                                                        : null}
                                                        
                                                        <div className="form-group">
                                                            <label htmlFor="form_title" className="required">{fieldName}</label>
                                                            <input value={values[index]} onChange={e => { let tempvalue = [...values];  tempvalue[index] = e.target.value; setValues(tempvalue)}} type="text" className="form-control"/>
                                                        </div>
                                                    </div>
                                                    )
                                            }
                                        
                                    })}


                                    

                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className="btn btn-success">Enregistrer</button>
                                        <NavLink className="btn btn-primary " to="/customers">Retour</NavLink>
                                    </div> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div>
    </div>
  );
}

export default ModifyCustomerDirectus;
