import { useEffect, useState } from "react";
import { useLocation, useNavigate,NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import profilePic from "../../img/profile-photos/1.png"

function MainNav() {
    const navigate = useNavigate();
    const logout = useLogout();
    const location = useLocation();
    const {auth} = useAuth();
    // const {auth} = useAuth();

    const signout = async () => {
        await logout();
    }
    
    useEffect(() => {
        window.bindLink()
    }, [])
    

    return (
        <nav id="mainnav-container">
            <div id="mainnav">
                <div id="mainnav-menu-wrap">
            <div className="nano has-scrollbar">
                <div className="nano-content">

                    {/* <!--Profile Widget--> */}
                    {/* <!--================================--> */}
                    <div id="mainnav-profile" className="mainnav-profile">
                        <div className="profile-wrap text-center">
                            <div className="pad-btm">
                                <img className="img-circle img-md" src={profilePic} alt="Profile Picture"/>
                            </div>
                            <a href="#profile-nav" className="box-block" data-toggle="collapse" aria-expanded="false">
                                <span className="pull-right dropdown-toggle">
                                    <i className="dropdown-caret"></i>
                                </span>
                                <p className="mnp-name">{auth.username}</p>
                            </a>
                        </div>
                        <div id="profile-nav" className="collapse list-group bg-trans">
                            <NavLink style={location.pathname.includes('/profile') ? {color:"#fff"} : {}} to='/profile' className="list-group-item">
                                <i className="demo-pli-male icon-lg icon-fw"></i> Modifier mon profil
                            </NavLink>
                            <NavLink style={location.pathname.includes('/credential') ? {color:"#fff"} : {}} to='/credential' className="list-group-item">
                            <i className="demo-pli-gear icon-lg icon-fw"></i> Modifier mot de passe
                            </NavLink>
                            {/* <a href="#" className="list-group-item">
                                <i className="demo-pli-gear icon-lg icon-fw"></i> Settings
                            </a> */}
                            {/* <a href="#" className="list-group-item">
                                <i className="demo-pli-information icon-lg icon-fw"></i> Help
                            </a> */}
                            <a onClick={() => signout()} href="#" className="list-group-item">
                                <i className="demo-pli-unlock icon-lg icon-fw"></i> Déconnexion
                            </a>
                        </div>
                    </div>


                    {/* <!--Shortcut buttons-->
                    <!--================================--> */}
                    {/* <div id="mainnav-shortcut" className="hidden">
                        <ul className="list-unstyled shortcut-wrap">
                            <li className="col-xs-3" data-content="My Profile" data-original-title="" title="">
                                <a className="shortcut-grid" href="#">
                                    <div className="icon-wrap icon-wrap-sm icon-circle bg-mint">
                                        <i className="demo-pli-male"></i>
                                    </div>
                                </a>
                            </li>
                            <li className="col-xs-3" data-content="Messages" data-original-title="" title="">
                                <a className="shortcut-grid" href="#">
                                    <div className="icon-wrap icon-wrap-sm icon-circle bg-warning">
                                        <i className="demo-pli-speech-bubble-3"></i>
                                    </div>
                                </a>
                            </li>
                            <li className="col-xs-3" data-content="Activity" data-original-title="" title="">
                                <a className="shortcut-grid" href="#">
                                    <div className="icon-wrap icon-wrap-sm icon-circle bg-success">
                                        <i className="demo-pli-thunder"></i>
                                    </div>
                                </a>
                            </li>
                            <li className="col-xs-3" data-content="Lock Screen" data-original-title="" title="">
                                <a className="shortcut-grid" href="#">
                                    <div className="icon-wrap icon-wrap-sm icon-circle bg-purple">
                                        <i className="demo-pli-lock-2"></i>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    {/* <!--================================-->
                    <!--End shortcut buttons--> */}


                    <ul id="mainnav-menu" className="list-group">

                        {/* <!--Category name--> */}
                        <li className="list-header">Navigation</li>

                        {/* <!--Menu list item--> */}
                        {['admin'].includes(auth?.roles) ? 
                                <li className={location.pathname ==="/" ? "active-link" : ""}>
                                <NavLink to="/">
                                {/* <a href="/"> */}
                                <i className="demo-pli-home"></i>
                                    
                                    <span className="menu-title">Dashboard</span>
                                {/* </a> */}
                                </NavLink>
                            </li>
                                :
                                <li className={location.pathname ==="/" ? "active-link" : ""}>
                                <NavLink to="/">
                                {/* <a href="/"> */}
                                <i className="demo-pli-home"></i>
                                    
                                    <span className="menu-title">Dashboard</span>
                                {/* </a> */}
                                </NavLink>
                            </li>}
                        
                        <li className={location.pathname.includes('/buildings') ? "active-sub" : ""}>
                            <NavLink style={location.pathname.includes('/buildings') ? {backgroundColor:"#25476a"} : {}} to="/buildings">
                                <i className="demo-pli-building"></i>
                                <span className="menu-title">Bâtiments</span>
                                <i className="arrow"></i>
                            </NavLink>

                            {/* <!--Submenu--> */}
                            <ul className={location.pathname.includes('/buildings') ? "collapse in" : "collapse"}>
                                {['admin'].includes(auth?.roles) ? 
                                <li><NavLink to="buildings">Voir mes batiments</NavLink></li>
                                :
                                <li><NavLink to="/buildings">Voir mes batiments</NavLink></li>}
                                {/* {['admin','manager'].includes(auth?.roles) ? 
                                <li><NavLink to="/buildings/add">Ajouter un batiment</NavLink></li>
                                :
                                null} */}

                                {['admin'].includes(auth?.roles) ? 
                                <li><NavLink to="/admin/conso">Consommation batiments</NavLink></li>
                                :
                                <li><NavLink to="/conso">Consommation batiments</NavLink></li>}



                                
                                
                                {/* <li><a href="dashboard-2.html">Dashboard 2</a></li> */}
                            </ul>
                        </li>

                        {['admin'].includes(auth?.roles) ?
                        <li className={location.pathname.includes('/devices') ? "active-sub" : ""}>
                        <NavLink style={location.pathname.includes('/devices') ? {backgroundColor:"#25476a"} : {}} to="/devices">
                            <i className="pli-router"></i>
                            <span className="menu-title">Appareil</span>
                            <i className="arrow"></i>
                        </NavLink>

                       
                        <ul className={location.pathname.includes('/devices') ? "collapse in" : "collapse"} aria-expanded="false">
                            
                            
                            {['admin'].includes(auth?.roles) ? 
                            <li><NavLink to="/devices">Voir mes appareils</NavLink></li>
                            :
                            <li><NavLink to="/devices">Voir mes appareils</NavLink></li>}
                            {/* {['admin'].includes(auth?.roles) ?
                            <li><NavLink to="devices/add">Ajouter un appareil</NavLink></li>
                            :
                            null} */}
                            
                        </ul>
                    </li>
                        :
                        <li className={location.pathname.includes('/devices') ? "active-link" : ""}>
                        {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/devices">
                                <i className="pli-router"></i>
                                <span className="menu-title">Appareils</span>
                            </NavLink>
                                :
                                <NavLink to="/devices">
                                <i className="pli-router"></i>
                                <span className="menu-title">Appareils</span>
                            </NavLink>}

                            {/* {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/admin/devices/add">
                                <i className="pli-router"></i>
                                <span className="menu-title">Ajouter un appareil</span>
                            </NavLink>
                                :
                                null
                                } */}
                            
                        </li>
                        }
                        
                        
                        

                        {/* <!--Menu list item--> */}

                        {/* <li className={location.pathname.includes('/users') ? "active-sub" : ""}>
                            <NavLink style={location.pathname.includes('/users') ? {backgroundColor:"#25476a"} : {}} to="/users">
                                <i className="pli-conference"></i>
                                <span className="menu-title">Utilisateurs</span>
                                <i className="arrow"></i>
                            </NavLink>

                           
                            <ul className={location.pathname.includes('/users') ? "collapse in" : "collapse"} aria-expanded="false">
                                
                                
                                {['admin'].includes(auth?.roles) ? 
                                <li><NavLink to="/users">Voir mes utilisateurs</NavLink></li>
                                :
                                <li><NavLink to="/users">Voir mes utilisateurs</NavLink></li>}
                                {['admin','manager'].includes(auth?.roles) ?
                                <li><NavLink to="/users/add">Ajouter un utilisateur</NavLink></li>
                                :
                                null}
                                
                            </ul>
                        </li> */}

                        {['admin'].includes(auth?.roles) ?
                        <li className={location.pathname.includes('/customers') ? "active-sub" : ""}>
                            <NavLink style={location.pathname.includes('/customers') ? {backgroundColor:"#25476a"} : {}} to="/customers">
                                <i className="pli-business-man"></i>
                                <span className="menu-title">Clients</span>
                                <i className="arrow"></i>
                            </NavLink>

                        
                            <ul className={location.pathname.includes('/customers') ? "collapse in" : "collapse"} aria-expanded="false">
                                <li><NavLink to="/customers">Voir mes clients</NavLink></li>
                                <li><NavLink to="/customers/add">Ajouter un client</NavLink></li>
                            </ul>
                        </li>
                    :
                    null
                    }


                    {['admin'].includes(auth?.roles) ?
                        <li className={location.pathname.includes('/sites') ? "active-sub" : ""}>
                        <NavLink style={location.pathname.includes('/sites') ? {backgroundColor:"#25476a"} : {}} to="/sites">
                            <i className="pli-router"></i>
                            <span className="menu-title">Sites</span>
                            <i className="arrow"></i>
                        </NavLink>

                       
                        <ul className={location.pathname.includes('/sites') ? "collapse in" : "collapse"} aria-expanded="false">
                            
                            
                            {['admin'].includes(auth?.roles) ? 
                            <li><NavLink to="/sites">Voir mes Sites</NavLink></li>
                            :
                            <li><NavLink to="/sites">Voir mes Sites</NavLink></li>}
                            {/* {['admin'].includes(auth?.roles) ?
                            <li><NavLink to="sites/add">Ajouter un appareil</NavLink></li>
                            :
                            null} */}
                            
                        </ul>
                    </li>
                        :
                        <li className={location.pathname.includes('/sites') ? "active-link" : ""}>
                        {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/sites">
                                <i className="pli-router"></i>
                                <span className="menu-title">Sites</span>
                            </NavLink>
                                :
                                <NavLink to="/sites">
                                <i className="pli-router"></i>
                                <span className="menu-title">Sites</span>
                            </NavLink>}


                            

                            {/* {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/admin/sites/add">
                                <i className="pli-router"></i>
                                <span className="menu-title">Ajouter un appareil</span>
                            </NavLink>
                                :
                                null
                                } */}
                            
                        </li>
                        }

                        
                    {['admin'].includes(auth?.roles) ?
                        <li className={location.pathname.includes('/areas') ? "active-sub" : ""}>
                        <NavLink style={location.pathname.includes('/areas') ? {backgroundColor:"#25476a"} : {}} to="/areas">
                            <i className="pli-router"></i>
                            <span className="menu-title">Zones techniques</span>
                            <i className="arrow"></i>
                        </NavLink>

                       
                        <ul className={location.pathname.includes('/areas') ? "collapse in" : "collapse"} aria-expanded="false">
                            
                            
                            {['admin'].includes(auth?.roles) ? 
                            <li><NavLink to="/areas">Voir mes zones techniques</NavLink></li>
                            :
                            <li><NavLink to="/areas">Voir mes zones techniques</NavLink></li>}
                            {/* {['admin'].includes(auth?.roles) ?
                            <li><NavLink to="areas/add">Ajouter un appareil</NavLink></li>
                            :
                            null} */}
                            
                        </ul>
                    </li>
                        :
                        <li className={location.pathname.includes('/areas') ? "active-link" : ""}>
                        {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/areas">
                                <i className="pli-router"></i>
                                <span className="menu-title">Zones techniques</span>
                            </NavLink>
                                :
                                <NavLink to="/areas">
                                <i className="pli-router"></i>
                                <span className="menu-title">Zones techniques</span>
                            </NavLink>}


                            

                            {/* {['admin'].includes(auth?.roles) ? 
                                <NavLink to="/admin/areas/add">
                                <i className="pli-router"></i>
                                <span className="menu-title">Ajouter un appareil</span>
                            </NavLink>
                                :
                                null
                                } */}
                            
                        </li>
                        }
                        
                    </ul>

                </div>
                {/* <div className="nano-pane" style="display: none;">
                    <div className="nano-slider" style="height: 1830px; transform: translate(0px, 0px);"></div>
                </div> */}
            </div>
        </div>
    </div>
    <script>
    </script>
</nav>
    );
  }
  
  export default MainNav;
  