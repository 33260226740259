import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import SelectGeneral from '../../utils/SelectGeneral';



function ListUser() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();
    const roleMapping = {
        manager: 'Utilisateur responsable',
        customer: 'Utilisateur standard',
        admin: 'Administrateur'
    }

    const [buildingData, setbuildingData] = useState([])
    const [userData, setUserData] = useState([])
    const [pagination, setPagination] = useState(1)
    const [customerData, setCustomerData] = useState([])
    const [customerFilter, setCustomerFilter] = useState('-');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/directus_routes/entities/getall.php').catch(e => {
                console.log(e.response)
            })

            console.log(response.data);
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer.push({'id': element.id, 'value': element.name})
            });
            setCustomerData(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

    const deleteUser = async () => {
        let index = deleteIndex;
        let pageAdd = ((pagination-1)*20) > 0 ? ((pagination-1)*20) : 0;
        index = index + pageAdd
        console.log(index);
        if (userData.length <= 0) {
            return
        }
        let temporaryUsersData = userData;
        console.log(temporaryUsersData);
        console.log(index);
        
        const id = temporaryUsersData[index].id;
        
        temporaryUsersData.splice(index, 1);
        console.log(temporaryUsersData);
        const response = await axiosPrivate.post('/deleteUser.php', {
            id: id
        })
        console.log(response);
        setDeleteIndex(null)
        setConfirmDelete(false)
        setUserData(temporaryUsersData);
    }

    const toggleConfirm = (index) => {
        setConfirmDelete(true);
        setDeleteIndex(index);
    }


    const getUserData = async () => {
        try {
            // const userList = await axiosPrivate.post('/getUserListAdmin.php', {
            //     'customer_id': customerFilter
            // })
            // console.log(userList?.data);

            const userList = await axiosPrivate.post('/directus_routes/user/getByEntity.php', {
                linked_entity : customerFilter
            })
            console.log(userList?.data);
            
            setUserData(userList?.data.data)
        } catch (error) {
            console.log(error)
            // setAuth({})
            // navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        // getUserData()
        getCustomerData();
    
      
    }, [])

    useEffect(() => {
        
    
      
    }, [userData])

    useEffect(() => {
        if (customerFilter !== '-') {
            getUserData()
            setConfirmDelete(false);
            setDeleteIndex(null)
        }
    
      
    }, [customerFilter])
    useEffect(() => {
        setConfirmDelete(false);
        setDeleteIndex(null)
    }, [pagination])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface utilisateurs</h3>
                <p>Visualisez tous vos comptes</p>
            </div>
        </div>

        <div id="page-content">

                                <div className="row">
                                        <div className="col-lg-12">

                                                {/* <!--Network Line Chart-->
                                                <!--===================================================--> */}
                                                <div id="demo-panel-network" className="panel">
                                                        <div className="panel-heading">

                                                                <h3 className="panel-title">Liste des utilisateurs</h3>
                                                        </div>
                                                        {customerFilter === '-' ? <div className="mar-btm pad-ver pad-lft">
                                                            <div style={{ display: 'inline-block' }} className="">
                                                                Selectionnez le client a consulter
                                                            </div>
                                                        </div>
                                                            :
                                                            null
                                                        }
                                                        <div className='pad-all'>
                                                            <SelectGeneral valueList={customerData} value={customerFilter} setter={setCustomerFilter} label='Filtre client' generalLabel="Selectionnez le client"/>
                                                        </div>

                                                        {confirmDelete === true ? 
                                                        <div className="col-lg-12">
                                                        <div className="col-lg-4"></div>
                                                        <div className="col-lg-4 confirm-area mar-btm"><p>Voulez vous vraiment supprimer l'utilisateur ?</p>
                                                        <div className='confirm-button'>
                                                        <button className='btn btn-danger' onClick={() => deleteUser()}>Supprimer</button> <button onClick={() => {setConfirmDelete(false); setDeleteIndex(null)}} className='btn btn-primary'>Annuler</button></div>
                                                        </div> 
                                                        <div className="col-lg-4"></div>
                                                    </div>
                                                    :
                                                    null}
                                                        <table className="table table-striped">
                                                                <thead>
                                                                    
                                                                <tr>
                                                                        <th>Nom</th>
                                                                        <th>Prenom</th>
                                                                        <th>Telephone</th>
                                                                        <th>Role</th>
                                                                        {['admin','manager'].includes(auth?.roles) ? 
                                                                        <th>Actions</th>
                                                                        :
                                                                        null}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {userData.length > 0 ?
                                                                    userData.slice((pagination-1)*20,(pagination)*20).map((user,index) => 
                                                                    <tr>
                                                                        <td>{user?.last_name}</td>
                                                                        <td >{user?.first_name}</td>
                                                                        <td >{user?.phone}</td>
                                                                        <td >{roleMapping[user?.roles]}</td>
                                                                        <td >
                                                                        
                                                                        {['admin','manager'].includes(auth?.roles) ? 
                                                                        
                                                                            <Link
                                                                            to={"modify"}
                                                                            state={{state: user}}
                                                                            >
                                                                            Modifier
                                                                            </Link>
                                                                            
                                                                        :
                                                                        null
                                                                        }
                                                                        <span> </span>

                                                                        <a
                                                                        className={'text-danger'}
                                                                        href='#'
                                                                        onClick={(e) => toggleConfirm(index)}
                                                                        >
                                                                        Supprimer
                                                                        </a>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    )
                                                                    :
                                                                    <tr>
                                                                        <td className='text-bold text-lg-center bg-primary' colSpan="13">Aucun utilisateur</td>
                                                                    </tr>
                                                                    }
                                                                </tbody>
                                                        </table>
                                                        <div className='pad-ver'>
                                                            {userData.length > 20 ?
                                                            <Pagination count={Math.ceil(userData.length/20)} onChange={(e,page) => setPagination(page)} color="primary" />
                                                            :
                                                            null}
                                                        </div>
                                                </div>
                                                
                                                
                                        </div>
                                </div>


                        </div>
    </div>
  );
}

export default ListUser;
