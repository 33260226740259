import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
// import ProfilePic from "../../public/assets/img/profile-img.jpg"
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import Logo from '../../img/logo.png'
import ProfilePic from '../../img/profile-photos/4.png'


function Navbar() {
    const navigate = useNavigate();
    const logout = useLogout();
    const {auth} = useAuth();
    const [test, settest] = useState(false)

    const signout = async () => {
        navigate('/');
        await logout();
    }
    const bindNifty = () => {
        window.bindNifty()
    }

    
    

    return (
        <header id="navbar">
            <div id="navbar-container" className="boxed">

                {/* <!--Brand logo & name-->
                <!--================================--> */}
                <div className="navbar-header">
                    <NavLink className="navbar-brand" to="/">
                    {/* assets/img/profile-img.jpg */}
                        <img src={Logo} alt="Nifty Logo" className="brand-icon"/>
                        <div className="brand-title">
                            <span className="brand-text" style={{color: "white"}}>Effivision</span>
                        </div>
                    </NavLink>
                </div>
                {/* <!--================================-->
                <!--End brand logo & name--> */}


                {/* <!--Navbar Dropdown-->
                <!--================================--> */}
                <div className="navbar-content">
                    <ul className="nav navbar-top-links">

                        {/* <!--Navigation toogle button-->
                        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~--> */}
                        <li className="tgl-menu-btn">
                            <a onClick={() => bindNifty()} className="mainnav-toggle" href="#">
                                <i className="demo-pli-list-view"></i>
                            </a>
                        </li>
                        {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
                        <!--End Navigation toogle button--> */}

                    </ul>
                    <ul className="nav navbar-top-links">

                        {/* <!--User dropdown-->
                        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~--> */}
                        <li id="dropdown-user" className="dropdown">
                            <a href="#" data-toggle="dropdown" className="dropdown-toggle text-right">
                                <span className="ic-user pull-right">
                                    {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
                                    <!--You can use an image instead of an icon.--> */}
                                    <img className="img-circle img-user media-object" src={ProfilePic} alt="Profile Picture"/>
                                    {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~--> */}
                                    {/* <i className="demo-pli-male"></i> */}
                                </span>
                                {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
                                <!--You can also display a user name in the navbar.--> */}
                                <div className="username hidden-xs">{auth.username}</div>
                                {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~--> */}
                            </a>


                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right panel-default">
                                <ul className="head-list">
                                    {/* <li>
                                        <a href="#"><i className="demo-pli-male icon-lg icon-fw"></i> Profile</a>
                                    </li> */}
                                    {/* <li>
                                        <a href="#"><span className="badge badge-danger pull-right">9</span><i className="demo-pli-mail icon-lg icon-fw"></i> Messages</a>
                                    </li> */}
                                    {/* <li>
                                        <a href="#"><span className="label label-success pull-right">New</span><i className="demo-pli-gear icon-lg icon-fw"></i> Settings</a>
                                    </li> */}
                                    {/* <li>
                                        <a href="#"><i className="demo-pli-computer-secure icon-lg icon-fw"></i> Lock screen</a>
                                    </li> */}
                                    <li>
                                        <a onClick={() => signout()} href="#"><i className="demo-pli-unlock icon-lg icon-fw"></i> Me déconnecter</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
                        <!--End user dropdown--> */}
                    </ul>
                </div>
                {/* <!--================================-->
                <!--End Navbar Dropdown--> */}

            </div>
        </header>
    );
  }
  
  export default Navbar;
  