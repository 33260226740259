
import { NavLink, useLocation, useNavigate } from "react-router-dom";
function Unauthorized() {

    return (
    <div id="container" class="cls-container">
        
		{/* <!-- HEADER -->
		<!--===================================================--> */}
		<div class="cls-header">
		    <div class="cls-brand">
		        <a class="box-inline" href="index.html">
		            {/* <!--<img alt="Nifty Admin" src="img/logo.png" class="brand-icon">--> */}
		            <span class="brand-title">Efficube<span class="text-thin">Admin</span></span>
		        </a>
		    </div>
		</div>
		
		{/* <!-- CONTENT -->
		<!--===================================================--> */}
		<div class="cls-content">
		    <h1 class="error-code text-info">401</h1>
		    <p class="h4 text-uppercase text-bold">Accès non authorisé !</p>
		    <div class="pad-btm">
            Désolé, mais vous n'avez pas les droits necessaires pour accéder a cette page.
		    </div>
		    
		    <hr class="new-section-sm bord-no"/>
		    <div class="pad-top">
                <NavLink to="/" className={"btn btn-primary"}>Retourner au dashboard</NavLink>
            </div>
		</div>
		
		
    </div>
    );
  }
  
  export default Unauthorized;
  