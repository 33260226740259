import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import SelectGeneral from '../../utils/SelectGeneral';
import getValueFromPath from '../../../utils/getValueFromPath';



function ListDevicesDirectus() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();
    const roleMapping = {
        manager: 'Utilisateur responsable',
        customer: 'Utilisateur standard',
        admin: 'Administrateur'
    }

    const [areaData, setAreaData] = useState([])
    const [userData, setUserData] = useState([])
    const [showData, setShowData] = useState([])
    
    const [pagination, setPagination] = useState(1)
    const [itemCountPagination, setItemCountPagination] = useState(0)
    const [noSite, setNoSite] = useState(false);
    const [noBuilding, setNoBuilding] = useState(false);
    
    const [customerData, setCustomerData] = useState([])
    const [siteData, setSiteData] = useState([])
    const [buildingData, setBuildingData] = useState([])
    const [areagData, setAreagData] = useState([])
    const [customerFilter, setCustomerFilter] = useState('-');
    const [siteFilter, setSiteFilter] = useState('-');
    const [buildingFilter, setBuildingFilter] = useState('-');
    const [areaFilter, setAreaFilter] = useState('-');

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const deleteSite = async () => {
        let index = deleteIndex;
        let pageAdd = ((pagination-1)*20) > 0 ? ((pagination-1)*20) : 0;
        index = index + pageAdd
        if (userData.length <= 0) {
            return
        }
        let temporaryUsersData = userData;
        
        const id = temporaryUsersData[index].id;
        
        temporaryUsersData.splice(index, 1);
        const response = await axiosPrivate.post('/directus_routes/building/delete.php', {
            id: id
        })
        console.log(response);
        setDeleteIndex(null)
        setConfirmDelete(false)
        setUserData(temporaryUsersData);
    }

    const updateBuildingFilter = () => {
        let tempMappingBuilding = [];
        if (siteFilter !== '-') {
            userData.forEach(site => {
                if (site.id == siteFilter) {
                    site.child_entity.forEach(building => {
                        tempMappingBuilding.push({'id': building.id, 'value': building.name})
                    })
                }     
            });
        } else {
            userData.forEach(site => {
                site.child_entity.forEach(building => {
                    tempMappingBuilding.push({'id': building.id, 'value': building.name})
                })
                
            });
        }
        setBuildingData(tempMappingBuilding)
    }

    const toggleConfirm = (index) => {
        setConfirmDelete(true);
        setDeleteIndex(index);
    }

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/directus_routes/entities/getall.php').catch(e => {
                console.log(e.response)
            })

            
            console.log(response.data);
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer.push({'id': element.id, 'value': element.name})
            });
            setCustomerData(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

    const parseShowData = () => {
        if (userData.length === 0) {
            setNoSite(true);
            setNoBuilding(true)
            setShowData([])
            return
        }
        let counter = 0;
        // userData.slice((pagination-1)*20,(pagination)*20).map((user,index) => 
        let toShowData = [];
        let totalCounter = 0;
        
        for (const site of userData) {
            totalCounter += site.child_entity.length;
            if (siteFilter !==  '-' && siteFilter !== site.id) {
                continue;
            }

            for (const building of site.child_entity) {
                if (buildingFilter !== '-' && buildingFilter != building.id) {
                    continue;
                }
                for (const area of building.child_entity) {
                    if (areaFilter !=='-' && areaFilter != area.id) {
                        continue
                    }
                    for (const device of area.child_entity) {
                        if ( counter >= (pagination-1)*20 &&counter < pagination*20) {
                            toShowData.push(device);
                        }
                        counter++;
                        
                    }
                    
                    
                }
                
                

            }
          
          }
          console.log(toShowData);
          setItemCountPagination(counter);
          setShowData(toShowData);
        

    }

    const getAreaData = async () => {
        try {
            let siteList;
            if (auth.roles === 'admin') {
                siteList = await axiosPrivate.post('/directus_routes/devices/getByCustomer.php', {
                    'linked_entity': customerFilter
                })
            } else {
                siteList = await axiosPrivate.post('/directus_routes/devices/getByCustomer.php')
            }

            
            console.log(siteList?.data?.data);
            setUserData(siteList?.data.data)
            let tempMappingSite = [];
            let tempMappingBuilding = [];
            let tempMappingArea = [];
            siteList?.data.data.forEach(site => {
                tempMappingSite.push({'id': site.id, 'value': site.name})
                console.log(site.child_entity);
                site.child_entity.forEach(building => {
                    tempMappingBuilding.push({'id': building.id, 'value': building.name})
                    building.child_entity.forEach(area => {
                        tempMappingArea.push({'id': area.id, 'value': area.name})
                    })
                })
                
            });
            setSiteData(tempMappingSite)
            setBuildingData(tempMappingBuilding)
            setAreaData(tempMappingArea);
        } catch (error) {
            console.log(error)
            // setAuth({})
            // navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        setNoSite(false)
        parseShowData()
      
    }, [siteFilter,buildingFilter, userData])

    useEffect(() => {

        auth.roles === 'admin' ? getCustomerData() : getAreaData()
        
    
      
    }, [])

    useEffect(() => {
        
    
      
    }, [userData])

    useEffect(() => {
        if (customerFilter !== '-') {
            getAreaData()
            setSiteFilter('-')
            setBuildingFilter('-')
            setConfirmDelete(false);
            setDeleteIndex(null)
        }
    
      
    }, [customerFilter])
    useEffect(() => {
        updateBuildingFilter();
    
      
    }, [siteFilter])
    useEffect(() => {
        setConfirmDelete(false);
        setDeleteIndex(null)
    }, [pagination])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface appareils</h3>
                <p>Visualisez tous vos appareils</p>
            </div>
        </div>

        <div id="page-content">

                                <div className="row">
                                        <div className="col-lg-12">

                                                {/* <!--Network Line Chart-->
                                                <!--===================================================--> */}
                                                <div id="demo-panel-network" className="panel">
                                                        <div className="panel-heading">

                                                                <h3 className="panel-title">Liste des appareils</h3>
                                                        </div>
                                                        {customerFilter === '-' ? <div className="mar-btm pad-ver pad-lft">
                                                            <div style={{ display: 'inline-block' }} className="">
                                                                Selectionnez le client a consulter
                                                            </div>
                                                        </div>
                                                            :
                                                            null
                                                        }
                                                        <div className='pad-all'>
                                                            <SelectGeneral valueList={customerData} value={customerFilter} setter={setCustomerFilter} label='Filtre client' generalLabel="Selectionnez le client"/>
                                                        </div>
                                                        {customerFilter !== '-' && siteData.length > 0? 
                                                        <div className='pad-all'>
                                                            <SelectGeneral valueList={siteData} value={siteFilter} setter={setSiteFilter} label='Filtre site' generalLabel="Selectionnez le site"/>
                                                        </div>
                                                        :null}

                                                        {customerFilter !== '-' && buildingData.length > 0? 
                                                        <div className='pad-all'>
                                                            <SelectGeneral valueList={buildingData} value={buildingFilter} setter={setBuildingFilter} label='Filtre batiment' generalLabel="Selectionnez le batiment"/>
                                                        </div>
                                                        :null}

                                                        
                                                        

                                                        {confirmDelete === true ? 
                                                        <div className="col-lg-12">
                                                        <div className="col-lg-4"></div>
                                                        <div className="col-lg-4 confirm-area mar-btm"><p>Voulez vous vraiment supprimer le site ?</p>
                                                        <div className='confirm-button'>
                                                        <button className='btn btn-danger' onClick={() => deleteSite()}>Supprimer</button> <button onClick={() => {setConfirmDelete(false); setDeleteIndex(null)}} className='btn btn-primary'>Annuler</button></div>
                                                        </div> 
                                                        <div className="col-lg-4"></div>
                                                    </div>
                                                    :
                                                    null}
                                                        <table className="table table-striped">
                                                                <thead>
                                                                    
                                                                <tr>
                                                                        <th>Nom</th>
                                                                        <th>Adresse</th>
                                                                        {/* <th>Type de zone</th>
                                                                        <th>Nombre de sondes</th> */}
                                                                        {/* {['admin','manager'].includes(auth?.roles) ? 
                                                                        <th>Actions</th>
                                                                        :
                                                                        null} */}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {userData.length > 0 ? 

                                                                    :null} */}
                                                                {showData.length > 0 ?
                                                                    showData.map((area,index) => 
                                                                    <tr>
                                                                        <td>{area?.name}</td>
                                                                        <td>{area?.address ? area?.address : "non renseigné"}</td>
                                                                        {/* <td>{getValueFromPath(area,"device_fields.area_type")}</td>
                                                                        <td >{getValueFromPath(area,'device_fields.area_type') === null || getValueFromPath(area,'area_fields.area_type') === undefined ? 'non renseigné' : getValueFromPath(area,'area_fields.area_type')}</td> */}
                                                                        {/* <td>{getValueFromPath(area,"area_fields.number_of_probes")}</td> */}
                                                                        {/* <td >{getValueFromPath(building,'building_fields.accomodation_number') === null || getValueFromPath(building,'building_fields.accomodation_number') === undefined ? 'non renseigné' : getValueFromPath(building,'building_fields.accomodation_number')
                                                                        }</td> */}
                                                                        
                                                                    </tr>
                                                                    
                                                                    )
                                                                    :
                                                                    
                                                                    <tr>
                                                                        <td className='text-bold text-lg-center bg-primary' colSpan="13">Aucun appareil</td>
                                                                    </tr>
                                                                    }


                                                                </tbody>
                                                        </table>
                                                        <div className='pad-ver'>
                                                            {userData.length > 20 ?
                                                            <Pagination count={Math.ceil(itemCountPagination/20)} onChange={(e,page) => setPagination(page)} color="primary" />
                                                            :
                                                            null}
                                                        </div>
                                                </div>
                                                
                                                
                                        </div>
                                </div>


                        </div>
    </div>
  );
}

export default ListDevicesDirectus;
