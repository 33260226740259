import { useEffect, useState,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken"
import BasicDatePicker from "../../utils/Datepicker";
// import SelectAutoWidth from "../../utils/SelectWithouthDefault";
import SelectWithouthDefault from "../../utils/SelectWithouthDefault";
import ChartConsommation from "../../utils/chartConsommation"
import CircularProgress from '@mui/material/CircularProgress';
import SelectGeneral from "../../utils/SelectGeneral";
function sortFunction(a, b) {
    if (a[0] === b[0]) {
        return 0;
    }
    else {
        return (a[0] < b[0]) ? -1 : 1;
    }
}

const ConsoGraphAdmin = () => {
    const [frequency,setFrequency] = useState([
        {
            id: 'm',
            name: 'Mensuel'
        },
        {
            id:'d',
            name:'Journalier'
        }
    ]);
    const [isLoading,setIsLoading] = useState(true);
    const [refresh,setRefresh] = useState(false);
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const {setAuth, auth} = useAuth();
    const navigate = useNavigate();
    const [noData,setNoData] = useState(false);
    const [noGui,setNoGui] = useState(false);
    const [dataSeries,setDataSeries] = useState(false);
    const [filter,setFilter] =  useState('m')

    const [customerData, setCustomerData] = useState([])
    const [customerFilter, setCustomerFilter] = useState('-');

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/adminGetCustomer.php').catch(e => {
                console.log(e.response)
            })
            console.log(response.data);
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer.push({'id': element.id, 'value': element.name})
            });
            setCustomerData(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

    const getConsoData = async() => {
        try {
            
            let response
            if(['admin'].includes(auth?.roles) && customerFilter !=='-') {
                 response = await axiosPrivate.post('/getConsoFromCustomer.php', {
                    frequency: filter,
                    customer_id: customerFilter
                })
            }
            else if(!['admin'].includes(auth?.roles)) {
                 response = await axiosPrivate.post('/getConsoFromCustomer.php', {
                    frequency: filter
                })
            }else {
                return
            }       
            setIsLoading(true);      
            
            
            let responseData = response.data?.data;
            console.log(response.data);
            let isData = false
            let hasGui = false
            let series = [];
            for (const [buildingName, buildingData] of Object.entries(responseData)) {
                const numberOfYear = buildingData.consommation.length;
                if (numberOfYear === 0) {
                    console.log(buildingData.gui);
                    if (buildingData.gui !== null && isData === false) {
                        hasGui = true;
                    }
                    continue;
                } else {
                    isData = true;
                    hasGui = true;
                }
                for (const [year, yearData] of Object.entries(buildingData.consommation)) {
                    let temporarySeriesData = [];
                    for (const [month, monthData] of Object.entries(yearData)) {
                        for (const [day, dayData] of Object.entries(monthData)) {
                            temporarySeriesData.push([dayData.chart_timestamp, parseInt(dayData.energie)])
                        }
                    }
                    temporarySeriesData.sort(sortFunction);
                    

                    
                    series.push({name:`${buildingData.title}-${year}`,data: temporarySeriesData})
                }
            }
            
            
            if (isData === false) {
                
                if (!refresh) {
                    setRefresh(true)
                    setFilter('d')
                } else {
                    setNoData(true);
                }
            } else {
                setNoData(false);
                setDataSeries(series);
            }
            if (hasGui === false) {
                setNoGui(true)
            } else {
                setNoGui(false)
            }
            setIsLoading(false);
            
              
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
    }
    useEffect(()=> {
        getConsoData()
    },[filter,customerFilter])

    useEffect(()=> {
        getCustomerData()
    },[])
    return(
        <div id="content-container">
            <div id="page-head">
                <div className="pad-all text-center">
                    <h3>Bienvenue sur votre espace consommation Effivision</h3>
                    <p>Visualisez votre consommation énergétique durant ces dernières années</p>
                </div>
            </div>

            <div style={{marginTop: '5vh'}}id="page-content">
                {/* {
                    noData && !isLoading ? null : 
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="fixed-fluid mar-lft">
                            <div className="pull-sm-left d-inline mar-btm">
                                <SelectWithouthDefault label="Fréquence" batimentList={frequency} batiment={filter} setBatiment={setFilter}/>
                            </div>
                        </div>
                    </div>
                </div>
                } */}

                {['admin'].includes(auth?.roles) ? 
                                <div className='pad-all'>
                                    <SelectGeneral valueList={customerData} value={customerFilter} setter={setCustomerFilter} label='Filtre client' generalLabel="Selectionnez le client"/>
                                </div>
                                    :
                                    null}
                
                
                
                {
                    isLoading || noData ? 
                    <div className="col-lg-12 mar-btm text-md-center text-xs-center text-sm-center text-lg-center">
                        {isLoading && customerFilter !== '-' ? 
                        <div style={{display : 'inline-block'}} className="">
                            <CircularProgress />
                        </div>
                        : 
                        null
                        }

                        {noData ? 
                        <div style={{display : 'inline-block', width: '100%', height: '100%'}} >
                            {noData && noGui ?
                            <p className="text-3x">Nous ne disposons pas des droits d'accès GRDF pour vos batiments <br/>nous sommes donc dans l'incapacité de vous afficher vos données</p> 
                            :
                            <p className="text-3x">Vos données ne sont pas accessibles pour le moment</p>
                            }
                        </div>
                        : null
                        }
                        
                    </div>
                     
                    :null
                    }
                
                {
                    !isLoading && !noData ?
                    noData && noGui ?<p>pas data ni gui</p> :
                    noData ? <p>pas de data</p> : 
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="demo-panel-network" className="panel">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Consomation en kWh</h3>
                                </div>

                                <div className="pad-all">
                                    <div id="highchartsTemp" 
                                    // style={{height:  `${100 + actifDevices*10}px`, width: '100%'}}
                                    >
                                        
                                        <ChartConsommation  series={dataSeries}/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            
            
                
                    
            
            </div>

        </div>
        
    )
}

export default ConsoGraphAdmin