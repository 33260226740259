import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect } from 'react';
import { useState } from 'react';

export default function ComboBox({options, state, setState, label}) {
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (state === null) {
      setValue(null)
    }
  },[state])

  
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo" // Set the selected value
      value={value}
      onChange={(event, newValue) => {
        setState(newValue.id);
        setValue(newValue.label)
      }}
      style={{ backgroundColor: 'white' }}
      options={options}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
