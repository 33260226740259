import axios from "axios";
const API_URL = "http://localhost/rest-api-auth/api/";
class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "login.php", {
        "email": email,
        "password" :password
      })
      .then(response => {
        //   console.log(response.data.message);
        if (response.data.message === "Successful login.") {
          localStorage.setItem("user", JSON.stringify(response.data.jwt));
        }
        return {data: response.data}
      }).catch(e => {
          // console.log('fdpsqpkfkjds');
          console.log(e);
          return e;
      }) 
        
      
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(email, password, roles, firstname, lastname, phone, customer_id) {
    return axios.post(API_URL + "create_user.php", {
      "email": email,
      "password": password,
      "roles": roles,
      "firstname": firstname,
      "lastname": lastname,
      "phone": phone,
      "customer_id": customer_id
    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}
export default new AuthService();