import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import getValueFromPath from '../../../utils/getValueFromPath';


function ListCustomerDirectus() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();
    const clientDataMapping = {
        "Nom du client" : 'name',
        "Nom social" : 'entity_fields.social_name',
        "Siret/RNA" : 'entity_fields.uuid',
        'Adresse' : 'address',
    }

    const [buildingData, setbuildingData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [pagination, setPagination] = useState(1)

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/directus_routes/entities/getall.php').catch(e => {
                console.log(e.response)
            })
            console.log(response);
            setCustomerData(response?.data?.data)
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        getCustomerData()
    }, [])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface client</h3>
                <p>Visualisez tous vos client et leurs informations</p>
            </div>
        </div>

        <div id="page-content">

                                <div className="row">
                                        <div className="col-lg-12">

                                                <div id="demo-panel-network" className="panel">
                                                        <div className="panel-heading">

                                                                <h3 className="panel-title">Liste des clients</h3>
                                                        </div>
                                                        <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        {Object.keys(clientDataMapping).map((value,name) => 
                                                                        <td>{value}</td>
                                                                        )}
                                                                        
                                                                            
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {customerData.length > 0 ?
                                                                    customerData.slice((pagination-1)*20,(pagination)*20).map((customer,index) => 
                                                                    <tr>
                                                                    {
                                                                        Object.values(clientDataMapping).map((value, index) => 
                                                                    
                                                                        <td>{getValueFromPath(customer,value)}</td>)
                                                                        }
                                                                        
                                                                        
                                                                    </tr>
                                                                    
                                                                    
                                                                    )
                                                                    :
                                                                    <tr>
                                                                        <td className='text-bold text-lg-center bg-primary' colSpan="13">Aucun Client</td>
                                                                    </tr>
                                                                    }
                                                                </tbody>
                                                        </table>
                                                        <div className='pad-ver'>
                                                            {customerData.length > 20 ?
                                                            <Pagination count={Math.ceil(customerData.length/20)} onChange={(e,page) => setPagination(page)} color="primary" />
                                                            :
                                                            null}
                                                        </div>
                                                </div>
                                                
                                                
                                        </div>
                                </div>


                        </div>
    </div>
  );
}

export default ListCustomerDirectus;
