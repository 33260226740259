

import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import SelectAutoWidth from '../../utils/Select';
import SelectWithouthDefault from '../../utils/SelectWithouthDefault';
import SelectGeneral from '../../utils/SelectGeneral';




function ModifyUser() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();
    const roleList = auth.roles ==='admin' ? [
        {
            id: 'customer',
            name: 'Utilisateur standard'
        },
        {
            id: 'manager',
            name: 'Utilisateur responsable'
        },
        {
            id: 'admin',
            name: 'Administrateur'
        },
] : [
    {
        id: 'customer',
        name: 'Utilisateur standard'
    },
    {
        id: 'manager',
        name: 'Utilisateur responsable'
    },
]


    const ignoreDataList = [];

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [customer_id, setCustomer_id] = useState('-');
    
    const [customerData, setCustomerData] = useState([])



    const [errFirstname, setErrFirstname] = useState('');
    const [errLastname, setErrLastname] = useState('');
    const [errPhone, setErrPhone] = useState('');
    const [errEmail, setErrEmail] = useState('');
    const [errPassword, setErrPassword] = useState('');
    const [errcustomer_id, setErrCustomer_id] = useState('');
    const [errRoles, setErrRoles] = useState('');

    const [success, setSuccess] = useState(false);
    
    const mappingFieldError = {
        "first_name": (msg) => {return setErrFirstname(msg)},
        "last_name": (msg) => {return setErrLastname(msg)},
        "phone": (msg) => {return setErrPhone(msg)},
        "roles": (msg) => {return setErrRoles(msg)},
        "email": (msg) => {return setErrEmail(msg)},
        'customer_id':(msg) => {return setErrCustomer_id(msg)}
    }

    const mappingFieldData = {
        "first_name": (msg) => {return setFirstname(msg)},
        "last_name": (msg) => {return setLastname(msg)},
        "phone": (msg) => {return setPhone(msg)},
        "email": (msg) => {return setEmail(msg)},
        "roles": (msg) => {return setRoles(msg)},
        'customer_id':(msg) => {return setCustomer_id(msg)}
    }

    const validatePhone = () => {
        if(!phone.replace(/\s/g,'').match(/[0-9]{10}/)) {
            setErrPhone('Numero de telephone incorrect')
            return false
        }
        return true;
    }

    const validateMail = () => {
        if(!email.replace(/\s/g,'').match(/.*@.*\..*/)) {
            setErrEmail('Email Incorrect')
            return false;
        } 
        return true
    }

    // const getCustomerData = async () => {
    //     try {
    //         const response = await axiosPrivate.post('/adminGetCustomer.php').catch(e => {
    //             console.log(e.response)
    //         })
    //         let tempCustomer = [];
    //         response?.data?.data.forEach(element => {
    //             tempCustomer.push({'id': element.id, 'value': element.name})
    //         });
    //         setCustomerData(tempCustomer);
    //     } catch (error) {
    //         console.log(error)
    //         setAuth({})
    //         navigate('/login',{ state: {from: location}, replace: true})
    //     }
        
    // }

    const getCustomerData = async () => {
        try {
            const response = await axiosPrivate.post('/directus_routes/entities/getall.php').catch(e => {
                console.log(e.response)
            })

            console.log(response.data);
            let tempCustomer = [];
            response?.data?.data.forEach(element => {
                tempCustomer.push({'id': element.id, 'value': element.name})
            });
            setCustomerData(tempCustomer);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (success === true) {
            return
        }
        let error = false
        let formData;
        if (auth.roles === 'admin') {
            formData = {
                'id' : userId,
                'first_name': firstname,
                'last_name' : lastname,
                'email': email,
                'roles' : roles,
                'phone': phone,
                'customer_id': customer_id
            }
       } else {
         formData = {
            'id' : userId,
            'first_name': firstname,
            'last_name' : lastname,
            'email': email,
            'roles' : roles,
            'phone': phone,
        }
       }
        
        console.log(formData);
        for (const [fieldName,fieldValue] of Object.entries(formData)) {
            if (fieldValue.length === 0) {
                error = true;
                mappingFieldError[fieldName]('Veuillez renseigner ce champ')
                
            } else if (fieldName === 'phone') {
                error = validatePhone() ? error: true;
            }
            else if (fieldName === 'email') {
                error = validateMail()? error: true; ;
            }

        }

        if (error) {
            return
        }
        
        try {
            const response = await axiosPrivate.post('/updateUser.php', formData).catch(e => {
                console.log(e.message);
            })
            console.log(response);
            // setSuccess(true);
            // setbuildingData(response?.data?.data);
        } catch (error) {
            console.log(error)
            // setAuth({})
            // navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }
    useEffect(() => {
        if (success === true) {
            setTimeout(() => {
                if (auth.roles === 'admin') {
                    navigate('/admin/users')
                }
                else {navigate('/users')}
            }, 3000);
            
        }
    
      
    }, [success])

    useEffect(() => {
        if (location?.state?.state === null || location?.state?.state === undefined) {
            navigate('/users');
            return
        }
        console.log(location.state.state);
        if (auth.roles === 'admin') {
            getCustomerData();
        }
        

        for (const [fieldName,fieldValue] of Object.entries(location.state.state)) {
            console.log(fieldName, fieldValue);
            if (fieldName === 'id') {
                setUserId(fieldValue)
            }
            if (fieldValue === null || ignoreDataList.includes(fieldName) || !mappingFieldData.hasOwnProperty(fieldName)) {
                continue;
            }
            else if (fieldValue.length !== 0) {
                mappingFieldData[fieldName](fieldValue)
            }
        }
    },[])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface utilisateurs</h3>
                <p>Modifier un des vos comptes</p>
            </div>
        </div>

        <div id="page-content">
            <div className="row">
            
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    {success ? 
                    <div className="alert alert-success">
                        <strong>Utilisateur mis à jour</strong> Vous allez être ramené a l'acceuil
                    </div>
                    : 
                    null
                    }
                    
                    <div id="demo-panel-network" className="panel">
                        <div className="panel-heading">
                            <div className="panel-control">
                            </div>
                            <h2 className="panel-title">Modifier un utilisateur</h2>
                        </div>

                        <div className="panel-body">
                            <form className='' name="form" onSubmit={(e) =>{ handleSubmit(e)}} wtx-context="F3729247-E399-4064-9DB8-3F2E6D9DF32F">
                                <div id="form">


                                    {errLastname.length > 0 ? 
                                    <div className="alert alert-danger">
                                        <strong>Erreur : </strong> {errLastname}
                                    </div>
                                    :
                                    null
                                    }
                                    
                                    <div className="form-group">
                                        <label htmlFor="form_title" className="required">Nom de famille</label>
                                        <input value={lastname} onChange={e => setLastname(e.target.value)} type="text" className="form-control"/>
                                    </div>

                                    {errFirstname.length > 0 ? 
                                    <div className="alert alert-danger">
                                        <strong>Erreur : </strong> {errFirstname}
                                    </div>
                                    :
                                    null
                                    }
                                    <div className="form-group">
                                        <label htmlFor="form_title" className="required">Prénom</label>
                                        <input value={firstname} onChange={e => setFirstname(e.target.value)} type="text" className="form-control"/>
                                    </div>

                                    {errPhone.length > 0 ? 
                                    <div className="alert alert-danger">
                                        <strong>Erreur : </strong> {errPhone}
                                    </div>
                                    :
                                    null
                                    }
                                    <div className="form-group">
                                        <label htmlFor="form_title" className="required">Telephone (format: 06 23 45 67 89) </label>
                                        <input pattern='([0-9](\s*)){0,10}' value={phone} onChange={e => e.target.validity?.valid ? setPhone(e.target.value): setPhone(phone)} type="text" className="form-control"/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="form_title" className="required">Role</label>
                                        <SelectWithouthDefault label='Role'  batiment={roles} setBatiment={setRoles} batimentList={roleList}/>
                                    </div>

                                    {errEmail.length > 0 ? 
                                    <div className="alert alert-danger">
                                        <strong>Erreur : </strong> {errEmail}
                                    </div>
                                    :
                                    null
                                    }
                                    <div className="form-group">
                                        <label htmlFor="form_title" className="required">Addresse mail</label>
                                        <input value={email} onChange={e => setEmail(e.target.value)} type="text" className="form-control" />
                                    </div>

                                    {auth.roles === 'admin' ? 
                                        <div className="form-group">
                                            <SelectGeneral valueList={customerData} value={customer_id} setter={setCustomer_id} label='Choix client' generalLabel="Selectionnez le client"/>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className="btn btn-success">Enregistrer</button>
                                        {auth.roles === 'admin' ? 
                                        <NavLink className="btn btn-primary " to="/admin/users/">Retour</NavLink>
                                        :
                                        <NavLink className="btn btn-primary " to="/users/">Retour</NavLink>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div>
    </div>
  );
}

export default ModifyUser;
