import axios from "axios";
// const BASE_URL = 'http://localhost/rest-api-auth/api';
const BASE_URL = 'https://api.efficube.com/rest-api-auth/api';


export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
})