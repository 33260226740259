import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectWithouthDefault({batiment, setBatiment, batimentList, label = ''}) {
  

  const handleChange = (event) => {
    console.log(event.target.value);
    setBatiment(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{backgroundColor: 'white', minWidth: 150 }}>
      <InputLabel id="demo-simple-select-label">{label.length > 0 ? label : 'Batiment'}</InputLabel>
        {batimentList.length > 0 ? 
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={batiment}
            label={label.length > 0 ? label : 'Batiment'}
            onChange={handleChange}
            autoWidth
          >
            {batimentList.map((building,index) => <MenuItem key={index} sx={{minWidth: 150 }} value={building.id}>{building.name}</MenuItem>)}
          </Select>
          :
          <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={batiment}
          onChange={handleChange}
          autoWidth
        >
        </Select>
        }
      </FormControl>
    </div>
  );
}