import { useEffect, useState,useRef } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require ("highcharts/modules/accessibility")(Highcharts);
Highcharts.setOptions({
    time: {
        timezone: 'Europe/Paris'
    },
    lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
        'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        decimalPoint: ",",
        downloadPNG: "Télécharger en image PNG",
        downloadJPEG: "Télécharger en image JPEG",
        downloadPDF: "Télécharger en document PDF",
        downloadSVG: "Télécharger en document Vectoriel",
        downloadCSV: "Télecharger en document CSV",
        downloadXLS: 'Télécharger en document XLS',
        exportButtonTitle: "Export du graphique",
        loading: "Chargement en cours...",
        printButtonTitle: "Imprimer le graphique",
        resetZoom: "Réinitialiser le zoom",
        resetZoomTitle: "Réinitialiser le zoom au niveau 1:1",
        thousandsSep: " ",
        printChart: "Imprimer le graphique",
        viewFullscreen: "Agrandir à la taille de l'écran"
    }
});

function sortFunction(a, b) {
    if (a[0] === b[0]) {
        return 0;
    }
    else {
        return (a[0] < b[0]) ? -1 : 1;
    }
}

const ChartConsommation = ({series}) => {
    const [isLoading,setIsLoading] = useState(true);
    const [option,setOption] = useState(null);


    const createChart = async() => {
        if (series !== null) {
            let chartTitle

        chartTitle = ""
        

        let chartOption = {
            chart: {
                zoomType: 'x',
                // marginBottom: actifDevices/2 * 25 + 50 
            },
            title: {
                text: null
            },
        
            yAxis: {
                title: {
                    text: chartTitle
                }
            },
        
            credits: {
                enabled: false
            },
        
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%b', this.value);
                    }
                    }
            },
        
            series: series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
        
            exporting: {
                chartOptions: {
                    chart: {
                        height: 1200
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                }
            }
        }
        setOption(chartOption)
        setIsLoading(false);
    }
            
            
            
              
        
    }

    useEffect(() => {
        createChart()
    },[series])
    return(
        <HighchartsReact
            containerProps={{ style: { width: "100%" } }}
            highcharts={Highcharts}
            options={option}
        />
    )
}

export default ChartConsommation;

// return(
//     <div className="col-lg-12 mar-btm pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
//     { isLoading ? 
//         <div style={{display : 'inline-block'}} className="">
//             <CircularProgress />
//         </div>
//     :
//     noData === false ?
//     <HighchartsReact
//         containerProps={{ style: { width: "100%" } }}
//         highcharts={Highcharts}
//         options={option}
//     />
//     :
//     noGui === false ? <p className="text-5x">Nous n'avons pas vos droit d'accès GRDF pour l'instant</p>
//     :
//     <p className="text-5x">Des droits d'accès grdf ont été détécté cependant aucune données n'a été récupérée pour l'instant</p>
//             }</div>
    
// )