import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequiredAuth = ({allowedRoles}) => {
    const {auth} = useAuth();
    const location = useLocation();

    return(
        allowedRoles.includes(auth?.roles)
        ? <Outlet/>
        : auth?.user
        ? <Navigate to="/unauthorized" state={{from: location}} replace />
        : <Navigate to="/login" state={{from: location}} replace />
    );
}

export default RequiredAuth;