import authService from "../../services/auth.service";
import {useRef, useState, useEffect} from 'react'
import useAuth from "../../hooks/useAuth";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";



function Login() {
	const {setAuth, persist, setPersist} = useAuth();
	const userRef = useRef();
	const errRef = useRef();

	const navigate = useNavigate()
	const location = useLocation();
	const from = location.state?.from?.pathname || "/"

	const axiosPrivate = useAxiosPrivate();
	const [user, setUser] = useState('')
	const [pwd, setPwd] = useState('')
	const [remember,setRemember] = useState(false);
	const [errMsg, setErrMsg] = useState('')
	const [errServ, setErrServ] = useState('')


	useEffect(()=> {
		userRef.current.focus()
	},[])
	
	useEffect(() => {
	  setErrMsg('')
	}, [user,pwd])

	const handleSubmit = async e => {
		e.preventDefault();
		if (user.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  )) {
			  try {
				console.log(pwd);
				const response = await axiosPrivate.post('/login.php',
				{"email" : user, "password": pwd})
				.then(response => {
					
					return response
				  })
				  console.log(response);
				const accessToken = response?.data?.jwt;
				const roles = response?.data?.roles;
				const username = response?.data?.user;
				const userId = response?.data?.id;
				setAuth({user: userId, roles, accessToken, username})
				setUser('');
				setPwd('')
				// if(from === '/' && roles ==='admin') {
				// 	navigate('/admin', {replace : true});
				// } else {
					navigate(from, {replace : true});
				// }
			  } catch (error) {
				  if (!error?.response) {
					  setErrServ('Pas de réponse serveur');
				  } 
				  else if (error.response?.status === 401) {
					setErrServ('Email ou mot de passe incorrect');
				  } else {
					setErrServ('Authentification échouée');
				  }
				  console.log(error.response);
				  errRef.current.focus()
			  }
			
			
			return;
		}
		setErrMsg('Entrez une addresse mail valide')
		
		
	}

	const togglePersist = () => {
		setPersist(prev=> !prev);
	}
	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist])
	
  return (
    <div id="container" className="cls-container">
		{/* <!-- BACKGROUND IMAGE -->
		<!--===================================================--> */}
		<div id="bg-overlay" className="" style={{backgroundImage: "none"}}></div>
		
		
		{/* <!-- LOGIN FORM --> */}
		{/* <!--===================================================--> */}
		<div className="cls-content">
		    <div className="cls-content-sm panel">
		        <div className="panel-body">
		            <div className="mar-ver pad-btm">
		                <h1 className="h3">Identification</h1>
		                <p>Connectez vous a votre compte</p>
		            </div>
		            <form onSubmit={handleSubmit}>
						{errServ.length > 0 ? <div ref={errRef} className="form-group text-light pad-ver text-center  bg-danger text-bold">
					<p>{errServ}</p>
					</div> :''}
					
						{errMsg.length > 0 ? <div className="form-group has-error has-feedback">
						<label for="demo-oi-errinput" className="control-label text-semibold">{errMsg}</label>
						<input value={user} onChange={(e) => setUser(e.target.value)} type="text" id="demo-oi-errinput" className="form-control"/>
						<i onClick={e=> setUser('')} className="demo-pli-cross form-control-feedback"></i>
					</div> :
					<div className="form-group">
						<input type="email" value={user} onChange={(e) => setUser(e.target.value)} ref={userRef} className="form-control" placeholder="Email" required autoComplete="off" />
					</div>
					}
		                <div className="form-group">
		                    <input type="password" value={pwd} onChange={e => setPwd(e.target.value)}  className="form-control" required placeholder="Mot de passe"/>
		                </div>
		                <div className="checkbox pad-btm text-left">
		                    <input checked={persist} onChange={togglePersist} id="demo-form-checkbox" className="magic-checkbox" type="checkbox"/>
		                    <label htmlFor="demo-form-checkbox">Se souvenir de moi</label>
		                </div>
						
		                <button className="btn btn-primary btn-lg btn-block" type="submit">Me connecter</button>
		            </form>
		        </div>
		
		        <div className="pad-all">
					<NavLink className="btn-link mar-rgt " to="/reset">Mot de passe oublié ?</NavLink>
		        </div>
		    </div>
		</div>
    </div>
  );
}

export default Login;
