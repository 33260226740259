import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken"
import BasicDatePicker from "../../utils/Datepicker";
import SelectAutoWidth from "../../utils/Select";
import SelectWithouthDefault from "../../utils/SelectWithouthDefault";
import PanelRemove from "../../utils/panelRemove";
import Highcharts, { map } from 'highcharts'
import { subDays, subHours } from 'date-fns'
import moment from 'moment';
import 'moment-timezone';



import HighchartsReact from 'highcharts-react-official'
import CircularProgress from '@mui/material/CircularProgress';
import { height, positions, width } from "@mui/system";
import axios from "../../../api/axios";
import SelectGeneral from "../../utils/SelectGeneral";
import { type } from "@testing-library/user-event/dist/type";
import ComboBox from "../../utils/Autocomplete";
import useApiCall from "../../../hooks/useApiCallWithAxiosPrivate";
import getFilterLabelFromObject from "../../../hooks/getFilterLabelFromObject";
import generateFilterByDepth from "../../../hooks/generateFilterByDepth";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);


window.moment = moment;
Date.prototype.ddmmyyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
// Highcharts.dateFormat("Month: %m Day: %d Year: %Y", 20, false);
Highcharts.setOptions({
    time: {
        timezone: 'Europe/Paris'
    },
    lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
            'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        decimalPoint: ",",
        downloadPNG: "Télécharger en image PNG",
        downloadJPEG: "Télécharger en image JPEG",
        downloadPDF: "Télécharger en document PDF",
        downloadSVG: "Télécharger en document Vectoriel",
        downloadCSV: "Télecharger en document CSV",
        downloadXLS: 'Télécharger en document XLS',
        exportButtonTitle: "Export du graphique",
        loading: "Chargement en cours...",
        printButtonTitle: "Imprimer le graphique",
        resetZoom: "Réinitialiser le zoom",
        resetZoomTitle: "Réinitialiser le zoom au niveau 1:1",
        thousandsSep: " ",
        printChart: "Imprimer le graphique",
        viewFullscreen: "Agrandir à la taille de l'écran"
    }
});

{/* <HighchartsReact
    highcharts={Highcharts}
    options={options}
/> */}




function countEntities(data) {
    let buildingCount = 0;
    let deviceCount = 0;
    let deviceWithDataCount = 0;

    function traverse(entity) {
        if (entity.entitie_type === 'building') {
            buildingCount++;
        } else if (entity.entitie_type === 'device') {
            deviceCount++;
            if (entity.device_fields && entity.device_fields.datas_connections) {
                for (let dataConnection of entity.device_fields.datas_connections) {
                    if (dataConnection.datas && dataConnection.datas.length > 0) {
                        deviceWithDataCount++;
                        break; // Break if at least one data point is found
                    }
                }
            }
        }

        if (entity.child_entity && entity.child_entity.length > 0) {
            entity.child_entity.forEach(child => traverse(child));
        }
    }

    traverse(data);

    return [buildingCount, deviceCount, deviceWithDataCount];
}




const DashboardDirectus = () => {
    const refresh = useRefreshToken();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();
    const chart = useRef();
    


    

    const controller = new AbortController();
    const intervalList = [
        { 'id': 15, 'name': '15 minutes' },
        { 'id': 30, 'name': '30 minutes' },
        { 'id': 60, 'name': '1 heure' },
        { 'id': 240, 'name': '4 heures' },
        { 'id': 720, 'name': '12 heures' },
        { 'id': 1440, 'name': '1 jour' }
    ]

    const filterTypeData = [
        { 'id': 'site', 'label': 'Site' },
        { 'id': 'building', 'label': 'Batiment' },
        { 'id': 'area', 'label': 'Zone' },
    ]
    const [filterTypeValue, setFilterTypeValue] = useState(null);

    const [interval, setInterval] = useState(15);
    const [from, setFrom] = useState(subDays(new Date(), 7));
    const [to, setTo] = useState(new Date());


    const [loadingData, setLoadingData] = useState(false);
    
    const [entitieFilterData, setEntitieFilterData] = useState(null);
    const [entitieFilterValue, setEntitieFilterValue] = useState(null);
    
    const [filterDepthData, setFilterDepthData] = useState(null);
    const [filterDepthValue, setFilterDepthValue] = useState(null);
    const [typeFilterData, setTypeFilterData] = useState(null);
    const [typeFilterValue, setTypeFilterValue] = useState(null);

    const [chartFilterSave, setChartFilterSave] = useState([[null,null,null]]);
    const [chartOptions, setChartOptions] = useState([]);
    
    const [meanDatas, setMeanDatas] = useState([]);


    const [buildingNumber, setBuildingNumber] = useState(0);
    const [deviceNumber, setDeviceNumber] = useState(0);
    const [activeDevicesNumber, setActiveDevicesNumber] = useState(0);



    

    const [dashboardData, setDashboardData] = useState(null);
    const [entitiesData, setEntitiesData] = useState(null);
    const [typeData, setTypeData] = useState(null);



    // ... other states as needed
  
    const fetchData = useApiCall();
  
    //!Filter and data retrieval start
    useEffect(() => {
      fetchData('/directus_routes/entities/getall.php', {}, 'GET', 
        (data) => {setEntitiesData(data?.data);}, 
        (error) => console.error('Error fetching data1:', error)
      );

        let TypeDataHolder;
      fetchData('/directus_routes/datas_type/getall.php', {}, 'GET', 
        (data) => {setTypeData(data?.data)}, 
        (error) => console.error('Error fetching data1:', error)
      );

    }, []);
  
    useEffect(() => {
        if (entitiesData === null ||typeData === null) {
            return
        }
        let entitieFilterTemp = [];
        entitiesData.forEach(entity => {
            entitieFilterTemp = [...entitieFilterTemp, ...generateFilterByDepth(entity,0)]
        });

    let typeFilterTemp = [
        {label : 'ECS',
        id : [4,3,1]}
    ];
      typeData.forEach(type => {
        typeFilterTemp = [...typeFilterTemp, ...generateFilterByDepth(type,0)]
    });

    setTypeFilterData(typeFilterTemp)

        setEntitieFilterData(entitieFilterTemp);
    },[entitiesData, typeData])


    useEffect(() => {
        if (entitieFilterValue === null) {
            return
        }
        if (from !== null && to !== null && interval !== null) {

            let datefrom = new Date(from).toISOString().slice(0, 10);
            let dateTo = new Date(to)
            if (dateTo.toISOString().slice(0, 10) == new Date().toISOString().slice(0, 10)) {
                dateTo.setMinutes(Math.floor(parseInt(dateTo.getMinutes())/10) * 10)
                let minutes = parseInt(dateTo.getMinutes()) === 0 ? '00' : dateTo.getMinutes();
                let hour = dateTo.getHours() < 10 ? '0'+dateTo.getHours() : dateTo.getHours();
                dateTo = dateTo.toISOString().slice(0, 10)+' ' +hour+':'+minutes+':00'
            } else {
                dateTo.setDate(dateTo.getDate() + 1)
                dateTo = dateTo.toISOString().slice(0, 10);
            }
            fetchData('/directus_routes/dashboard/getData.php', {linked_entity : entitieFilterValue, interval: interval, from: datefrom, to: dateTo}, 'POST', 
        (data) => {setDashboardData(data?.data); setLoadingData(false)}, 
        (error) => console.error('Error fetching data1:', error)
      );
        }
        
        setLoadingData(true);
        // 
        
      
      
    },[from, to, interval, entitieFilterValue])

    useEffect(() => {
        setChartFilterSave([[null,null,null]])
        setFilterTypeValue(null)
        setFilterDepthValue(null)
    },[entitieFilterValue])

    useEffect(() => {
        if (filterTypeValue === null) {
            return
        }
        dashboardData.forEach(entity => {
            let depthTemp;
            switch (filterTypeValue) {
                case 'site':
                    depthTemp = 1;
                    break;
                case 'building':
                    depthTemp = 2;
                    break;
                case 'area':
                    depthTemp = 3;
                break;
            
                default:
                    break;
            }
            setFilterDepthData(generateFilterByDepth(entity,depthTemp))
        });
        setFilterDepthValue(null)
        
    },[filterTypeValue])

    useEffect(() => {
        // console.log(dashboardData);
        if (dashboardData === null) {
            return
        }
        let tempOption = [];
        let tempMeanData = [];
        console.log(chartFilterSave);
        chartFilterSave.forEach(filterSave => {
            let [seriesData,meanData] = filterData( filterSave[0], filterSave[1],filterSave[2]);
            
            if (Array.isArray(seriesData)) {
                tempOption.push(insertSerieIntoOptions(seriesData));
            }
            tempMeanData.push(meanData);

            
        });
        const result = countEntities(dashboardData[0]);

        setBuildingNumber(result[0]);
        setDeviceNumber(result[1]);
        setActiveDevicesNumber(result[2]);
        tempMeanData.forEach(subArray => {
            subArray.sort((a, b) => b.mean - a.mean);
          });
        setMeanDatas(tempMeanData);
        setChartOptions(tempOption);
    }, [dashboardData,chartFilterSave])
    useEffect(() => {
        if (chart && chart.current && chart.current.chart) {
            // chart.current.chart.downloadCSV();
            const container = chart.current.container.current;

            container.style.height = "100%";
            container.style.width = "100%";
            chart.current.chart.reflow();
        }
    },[chartOptions])

    useEffect(()=> {
        setTypeFilterValue(null)
    },[filterDepthValue])

    function addChartFilter() {
        if (dashboardData === null || dashboardData === undefined || dashboardData.length === 0) {
            return;
        }
    
        // Create a new array by spreading the existing state and adding the new filter
        const newChartFilterSave = [...chartFilterSave, [filterTypeValue, filterDepthValue, typeFilterValue]];
        setChartFilterSave(newChartFilterSave); // Update state with the new array
    }



    function insertSerieIntoOptions(data) {
        let option = {
            chart: {
                zoomType: 'x',
                // marginBottom: actifDevices/2 * 25 + 50 
            },
            title: {
                text: ''
            },

            yAxis: {
                title: {
                    text: 'valeurs'
                }
            },

            credits: {
                enabled: false
            },

            xAxis: {
                accessibility: {
                    rangeDescription: 'Période'
                },
                dateTimeLabelFormats: {
                    hour: '%H:%M'
                },
                tickInterval: 10800000,
                type: 'datetime',
            },

            series: data,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },

            exporting: {
                buttons: {
                   contextButton: {
                    menuItems: [
                        'downloadCSV','downloadXLS'
                     ]
                   },
                }
             },
        }
        return option;
    }
    

    function filterData(entityType = null, entityId = null, dataTypes = null) {
        if (dashboardData === null || dashboardData === undefined || dashboardData.length === 0) {
            return
        }
        let result = [];
        let meanResult = [];
    
        function isDataTypeMatch(connDataType) {
            if (Array.isArray(dataTypes)) {
                return dataTypes.includes(connDataType);
            }
            return dataTypes === null || connDataType === dataTypes;
        }
    
        function extractData(entity) {
            if (entity.device_fields && entity.device_fields.datas_connections) {
                entity.device_fields.datas_connections.forEach(conn => {
                    if (conn.datas && conn.datas.length > 0 && isDataTypeMatch(conn.data_type)) {
                        result.push({
                            name: entity.name,
                            data: conn.datas.map(d => [d.timestamp, d.value])
                        });

                        if (conn.mean !== undefined) {
                           meanResult.push({
                            name: entity.name,
                            mean : conn.mean,
                            unit :  typeData.find(item => item.id === conn.data_type)?.type_unit
                           }) 
                        }
                    }
                });
            } else if (entity.child_entity && entity.child_entity.length > 0) {
                entity.child_entity.forEach(extractData);
            }
        }
    
        function traverse(entity) {
            if ((entityType === null || entity.entitie_type === entityType) &&
                (entityId === null || entity.id === entityId)) {
                extractData(entity);
            } else if (entity.child_entity && entity.child_entity.length > 0) {
                entity.child_entity.forEach(traverse);
            }
        }
    
        traverse(dashboardData[0]);
        return [result,meanResult];
    }

    
    

    
    //! Filter and data retrieval end

   

    return (

        <div id="content-container">
            <div id="page-head">
                <div className="pad-all text-center">
                    <h3>Bienvenue sur votre Dashboard Effivision</h3>
                    <p>Descendez pour visualiser vos données et statistiques énergétiques
                    </p>
                </div>
            </div>

            <div id="page-content">

                <div className="col-md-4">
                    <div className="panel panel-info panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-building icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{buildingNumber}</p>
                            <p className="mar-no">Bâtiments</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-mint panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-router icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{deviceNumber}</p>
                            <p className="mar-no">Appareils</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel panel-danger panel-colorful media middle pad-all">
                        <div className="media-left">
                            <div className="pad-hor">
                                <i className="pli-router-2 icon-3x"></i>
                            </div>
                        </div>
                        <div className="media-body">
                            <p className="text-2x mar-no text-semibold">{activeDevicesNumber}</p>
                            <p className="mar-no">Appareils actif</p>
                        </div>
                    </div>
                </div>
                {/* {isLoading && customerFilter !== '-' ? <div className="col-lg-12 mar-btm pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
                    <div style={{ display: 'inline-block' }} className="">
                        <CircularProgress />
                    </div>
                </div>
                    :
                    null
                } */}

                <div className="row">
                    <div className="col-md-4">
                        
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
                <div className="row">
                        <div className="col-lg-12">

                            <div className="col-lg-6">
                                <div className="fixed-fluid mar-rgt">
                                    <div className="pull-sm-left d-inline mar-btm">
                                    {entitieFilterData !== null && 
                        <ComboBox options={entitieFilterData} state={entitieFilterValue} setState={setEntitieFilterValue} label={"Filtre Client"} />
                        } 
                        {entitieFilterValue !== null && 
                        <ComboBox options={filterTypeData} state={filterTypeValue} setState={setFilterTypeValue} label={"Type du filtre hierarchique"} />
                        } 
                        {filterDepthData !== null && 
                        <ComboBox options={filterDepthData} state={filterDepthValue} setState={setFilterDepthValue} label={"Filtre hierarchique"} />
                        }
                        {typeFilterData !== null && entitieFilterValue !== null &&
                        <ComboBox options={typeFilterData} state={typeFilterValue} setState={setTypeFilterValue} label={"Filtre Type"} />
                        } 

                                    </div>
                                    <div className="pull-sm-left d-inline mar-btm">
                                        <SelectWithouthDefault batiment={interval} setBatiment={setInterval} batimentList={intervalList} label='Interval données' />

                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="fixed-fluid mar-btm">
                                    <div className="fixed-sm-250 pull-sm-right">
                                        <div id="demo-dp-range">
                                            <div className="input-daterange input-group" id="datepicker">
                                                <BasicDatePicker label='Date Début' main='from' to={to} from={from} setFrom={setFrom} />
                                                <span className="input-group-addon">to</span>
                                                <BasicDatePicker label='Date Fin' main='to' to={to} from={from} setTo={setTo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {entitieFilterValue !==null ?
                                    <div className="pull-sm-left  d-inline mar-ver mar-lft">
                                        <button onClick={addChartFilter} className="btn btn-primary">Ajouter graphique</button>
                                    </div>
                                    :
                                    null}


                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {loadingData && entitieFilterValue !== null ? <div className="col-lg-12 mar-btm pad-ver text-md-center text-xs-center text-sm-center text-lg-center">
                            <div style={{ display: 'inline-block' }} className="">
                                <CircularProgress />
                            </div>
                        </div>
                            :
                            null
                        }


                        {chartOptions && chartOptions.length > 0 && chartOptions.map((options, index) => {
                            // Check if there are series in the options
                            if (options.series && options.series.length > 0) {
                                return (
                                    <PanelRemove key={index} index={index} title={'delete'}>
                                        <HighchartsReact
                                            containerProps={{ style: { width: "100%" } }}
                                            ref={chart}
                                            highcharts={Highcharts}
                                            options={options}
                                        />


                        <div class="panel panel-info">
					            <div class="panel-heading">
					                <h3 class="panel-title">Données moyennes du {from.ddmmyyyy()} au {to.ddmmyyyy()}</h3>
					            </div>
					            <div class="panel-body">
                                {meanDatas[index].map((meanObject) =>  {return(<p key={index}>{meanObject.name} : {meanObject.mean.toFixed(1)}{meanObject?.unit}</p>)})}
					            </div>
					        </div>
                                    </PanelRemove>
                                );
                            } else {
                                // If no series, return a div with a message
                                return <PanelRemove key={index}  index={index} 
                                title={'delete'}>
                                <div class="panel panel-warning panel-colorful media middle pad-all">
                                                    {/* <div class="media-left">
                                                        <div class="pad-hor">
                                                            <i class="demo-pli-file-word icon-3x"></i>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <p class="text-2x mar-no text-semibold">241</p>
                                                        <p class="mar-no">Documents</p>
                                                    </div> */}
                                                    Aucun appareil ou donnée retrouvé pour cette option
                                                </div>
                                {/* <p>{option.title.text} : Aucun appareil ou donnée retrouvé</p> */}
                            </PanelRemove>;
                            }
                        })}
                        
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default DashboardDirectus