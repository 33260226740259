import {useRef, useState, useEffect} from 'react'
import { NavLink, useLocation, useNavigate, Navigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import useLogout from "../../../hooks/useLogout";




function ChangePassword() {
	const {auth} = useAuth();

	const errRef = useRef();

	const navigate = useNavigate()
	const location = useLocation();
	const from = location.state?.from?.pathname || "/"

	const axiosPrivate = useAxiosPrivate();

	const [pwd, setPwd] = useState('')
	const [confirmPwd, setConfirmPwd] = useState('')
	const [oldPwd, setOldPwd] = useState('')

	const [errMsg, setErrMsg] = useState('')
	const [errServ, setErrServ] = useState('')
    
    const logout = useLogout();

    const signout = async () => {
        
        await logout();
        navigate('/reset');
    }


	// useEffect(()=> {
	// 	errRef.current.focus()
	// },[])
	
	// useEffect(() => {
	//   setErrMsg('')
	// }, [pwd])

	const handleSubmit = async e => {
		e.preventDefault();
        if(pwd !== confirmPwd) {
            setErrServ('Les deux champs ne sont pas identiques')
            return
        }
			  try {
				console.log(pwd);
				const response = await axiosPrivate.post('/changePassword.php',
				{"newPassword": pwd, "oldPassword" : oldPwd })
				.then(response => {
					
					return response
				  })
                  if (response?.data?.error) {
                    setErrServ(response?.data?.error);
                  } else {
					if (auth.roles === 'admin') {
						navigate('/admin', {replace : true});
					} else {
						navigate('/', {replace : true});
					}
				  }

                  console.log(response);

                  
                
					
				
			  } catch (error) {
				  if (!error?.response) {
					  setErrServ('Pas de réponse serveur');
				  } 
			  }
			
			
			return;
		
		
	}

	
  return (
    <div id="container" className="cls-container">
		{/* <!-- BACKGROUND IMAGE -->
		<!--===================================================--> */}
		<div id="bg-overlay" className="" style={{backgroundImage: "none"}}></div>
		
		
		{/* <!-- LOGIN FORM --> */}
		{/* <!--===================================================--> */}
		<div className="cls-content">
		    <div className="cls-content-sm panel">
		        <div className="panel-body">
		            <div className="mar-ver pad-btm">
		                <h1 className="h3">Changement de mot de passe</h1>
		                <p>Renseignez les infos des champs</p>
		            </div>
		            <form onSubmit={handleSubmit}>
						{errServ.length > 0 ? <div className="form-group text-light pad-ver text-center  bg-danger text-bold">
					<p>{errServ}</p>
					</div> :''}
					
                        <div className="form-group">
		                    <input ref={errRef} type="password" value={oldPwd} onChange={e => setOldPwd(e.target.value)}  className="form-control" required placeholder="Ancien mot de passe"/>
		                </div>

		                <div className="form-group">
		                    <input ref={errRef} type="password" value={pwd} onChange={e => setPwd(e.target.value)}  className="form-control" required placeholder="Nouveau de passe"/>
		                </div>

                        <div className="form-group">
		                    <input type="password" value={confirmPwd} onChange={e => setConfirmPwd(e.target.value)}  className="form-control" required placeholder="Confirmer mot de passe"/>
		                </div>

						
		                <button className="btn btn-primary btn-lg btn-block" type="submit">Valider</button>
		            </form>
		        </div>
                <div className="pad-all">
                <a onClick={() => signout()} href="#">Mot de passe oublié ?</a>
		        </div>
		    </div>
		</div>
    </div>
  );
}

export default ChangePassword;
