import useAuth from "./useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
    const {setAuth} = useAuth()
    const navigate = useNavigate();

    const logout = async () => {
        setAuth(prev => {
            return {logout: true}
        })
        try {
            // const response = await axios.get('http://localhost/rest-api-auth/api/logout.php', {
            const response = await axios.get('https://api.efficube.com/rest-api-auth/api/logout.php', {
                withCredentials: true
            });
            return navigate('/login');
        } catch (error) {
            console.error(error)
        }
    }
    return logout;

}

export default useLogout;

