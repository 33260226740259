import React, { useEffect, useRef } from 'react';

const StreetView = ({ apiKey, initialPosition }) => {
  const mapRef = useRef(null);
  const panoramaRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      initMap();
    };

    document.head.appendChild(script);

    return () => {
      // Clean up: remove the script
      document.head.removeChild(script);
    };
  }, [apiKey, initialPosition]);

  const initMap = () => {
    if (!mapRef.current) {
      mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
        center: initialPosition,
        zoom: 14,
        streetViewControl: false,
      });

      panoramaRef.current = new window.google.maps.StreetViewPanorama(
        document.getElementById('street-view'),
        {
          position: initialPosition,
          pov: { heading: 0, pitch: 0 },
          zoom: 1,
        }
      );

      mapRef.current.setStreetView(panoramaRef.current);
    } else {
      // If the map instance already exists, update its position
      mapRef.current.panTo(initialPosition);
      panoramaRef.current.setPosition(initialPosition);
    }
  };

  return (
    <div>
      <div id="map" style={{ height: '400px', width: '50%', float: 'left' }}></div>
      <div id="street-view" style={{ height: '400px', width: '50%', float: 'left' }}></div>
    </div>
  );
};

export default StreetView;
