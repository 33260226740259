import Login from "./view/Login";
import RequiredAuth from "./RequireAuth";
import PersistLogin from "./PersistLogin";
import Layout from "./Layout";
import { Routes, Route } from "react-router-dom";
import TestRefresh from "./TestRefresh";
import Dashboard from "./view/Dashboard";
import ListBuilding from "./view/buildings/ListBuilding";
import AddBuilding from "./view/buildings/AddBuilding";
import ModifyBuilding from "./view/buildings/ModifyBuilding";
import ListDevices from "./view/devices/ListDevices";
import ModifyDevice from "./view/devices/ModifyDevice";
import ListUser from "./view/user/ListUser";
import AddUser from "./view/user/AddUser";
import ModifyUser from "./view/user/ModifyUser";
import ListCustomer from "./view/customer/ListCustomer";
import ModifyCustomer from "./view/customer/ModifyCustomer";
import AddCustomer from "./view/customer/AddCustomer";
import ModifyProfile from "./view/profile/ModifyProfile";
import AnalyserBuilding from "./view/buildings/AnalyserBuilding";
import ListGRDF from "./view/GRDF/ListBuilding";
import ConsoGraph from "./view/GRDF/ConsoGraph";
import EmbedNavigator from './view/GRDF/EmbedNavigator';
import LinkBuilding from "./view/buildings/LinkBuilding";
import DashboardAdmin from "./view/DashboardAdmin";
import ListUserAdmin from "./view/user/listUserAdmin";
import ListBuildingAdmin from "./view/buildings/ListBuildingAdmin";
import ListDevicesAdmin from "./view/devices/ListDevicesAdmin";
import ModifyBuildingAdmin from "./view/buildings/AddBuildingAdmin";
import AddBuildingAdmin from "./view/buildings/AddBuildingAdmin";
import AddDevice from "./view/devices/AddDevice";
import ResetPasswordLink from "./view/profile/ResetPasswordLink";
import ResetPassword from "./view/profile/ResetPassword";
import ChangePassword from "./view/profile/ChangePassword";
import ConsoGraphAdmin from "./view/GRDF/ConsoGraphAdmin";
import ListCustomerDirectus from "./view/customer/ListCustomerDirectus";
import ModifyCustomerDirectus from "./view/customer/ModifyCustomerDirectus";
import AddCustomerDirectus from "./view/customer/AddCustomerDirectus";
import ListSite from "./view/sites/listSite";
import ModifySite from "./view/sites/ModifySite";
import AddSite from "./view/sites/AddSite";
import ListBuildingDirectus from "./view/buildings/ListBuildingDirectus";
import ModifyBuildingDirectus from "./view/buildings/ModifyBuildingDirectus";
import AddBuildingDirectus from "./view/buildings/AddBuildingDirectus";
import ListArea from "./view/area/ListAreaDirectus";
import MapBox from "./view/buildings/MapBox";
import ModifyArea from "./view/area/ModifyArea";
import ListDevicesDirectus from "./view/devices/ListDevicesDirectus";
import ListConnections from "./view/datas_connection/ListConnections";
import DashboardDirectus from "./view/dashboard/DashboardDirectus";








// import Unauthorized from "./Unauthorized";
function App() {
    return(
        <Routes>
            <Route path="/" element={<Layout/>}>
                {/* public routes */}
                <Route path="login" element={<Login/>} />
                <Route path="/reset" element={<ResetPasswordLink/>} />
                <Route path="/password" element={<ResetPassword/>} />

                {/* <Route path="unauthorized" element={<Unauthorized/>}/> */}

                {/* Protected Routes */}
                <Route element={<PersistLogin/>}>
                    <Route element={<RequiredAuth allowedRoles={['manager','customer','admin']}/>}>
                        <Route path="/" element={<DashboardDirectus/>} />
                        <Route path="/map" element={<MapBox/>} />
                        <Route path="/buildings" element={<ListBuildingDirectus/>} />
                        <Route path="/areas" element={<ListArea/>} />
                        <Route path="/devices" element={<ListDevicesDirectus/>} />
                        <Route path="/connections" element={<ListConnections/>} />
                        <Route path="/users" element={<ListUser/>} />
                        <Route path="/profile" element={<ModifyProfile/>} />
                        <Route path="/credential" element={<ChangePassword/>} />
                        
                        {/* <Route path="/grdf" element={<ListGRDF/>} /> */}
                        <Route path="/conso" element={<ConsoGraph/>} />
                    </Route>
                    <Route element={<RequiredAuth allowedRoles={['manager','admin']}/>}>
                        {/* <Route path="/buildings/modify" element={<ModifyBuildingDirectus/>} />
                        <Route path="/buildings/add" element={<AddBuildingDirectus/>} /> */}
                        {/* <Route path="/areas/modify" element={<ModifyArea/>} /> */}
                        <Route path="/buildings/analyser" element={<AnalyserBuilding/>} />
                        <Route path="/buildings/link/grdf" element={<LinkBuilding/>} />
                        {/* <Route path="/devices/modify" element={<ModifyDevice/>} /> */}
                        <Route path="/users/add" element={<AddUser/>} />
                        <Route path="/users/modify" element={<ModifyUser/>} />
                        
                    </Route>
                    <Route element={<RequiredAuth allowedRoles={['admin']}/>}>
                        {/* <Route path="/customers" element={<ListCustomer/>} /> */}
                        <Route path="/customers" element={<ListCustomerDirectus/>} />
                        {/* <Route path="/customers/modify" element={<ModifyCustomer/>} /> */}
                        <Route path="/customers/modify" element={<ModifyCustomerDirectus/>} />
                        {/* <Route path="/customers/add" element={<AddCustomer/>} /> */}
                        <Route path="/customers/add" element={<AddCustomerDirectus/>} />
                        <Route path="/admin/users" element={<ListUserAdmin/>} />
                        <Route path="/admin/users/modify" element={<ModifyUser/>} />

                        <Route path="/sites" element={<ListSite/>} />
                        {/* <Route path="/sites/modify" element={<ModifySite/>} />
                        <Route path="/sites/add" element={<AddSite/>} /> */}

                        {/* <Route path="/admin/buildings" element={<ListBuildingDirectus/>} /> */}
                        <Route path="/admin/conso" element={<ConsoGraphAdmin/>} />
                        {/* <Route path="/admin/buildings/modify" element={<ModifyBuildingDirectus/>} /> */}
                        {/* <Route path="/admin/devices" element={<ListDevicesAdmin/>} /> */}
                        {/* <Route path="/admin/devices/modify" element={<ModifyDevice/>} /> */}
                        {/* <Route path="/devices/add" element={<AddDevice/>} /> */}
                        {/* <Route path="/admin/devices/add" element={<AddDevice/>} /> */}
                    </Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default App;