import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import SelectWithouthDefault from "../../utils/SelectWithouthDefault";
import DatePickerWitouthMin from "../../utils/DatepickerWithouthMin";
import { subDays,subHours } from 'date-fns'


function AnalyserBuilding() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const comeFrom = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();
	const [from, setFrom] = useState(subDays(new Date(), 7));
    const [to, setTo] = useState(new Date());

    const [buildingData, setbuildingData] = useState([])
    const [batiments, setBatiments] = useState([])
    const [conso, setConso] = useState(null)

    const [filter, setFilter] = useState('')
	

    const [pagination, setPagination] = useState(1)

    const getBuildingData = async () => {
        try {
            const response = await axiosPrivate.post('/getBuildingList.php')
            setbuildingData(response?.data?.data);
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
        
    }

	const getConsoFromBuilding = async () => {
		try {
			const response = await axiosPrivate.post('/getConsoFromBuilding.php', {
				from: from,
				to: to,
				building_id : filter
			})
            // setbuildingData(response?.data?.data);
			console.log(response.data);
			setConso(response?.data?.data);
            
        } catch (error) {
            console.log(error)
            setAuth({})
            navigate('/login',{ state: {from: location}, replace: true})
        }
	}
    useEffect(() => {
        getBuildingData()
    
      
    }, [])

	useEffect(() => {
        if(filter.length > 0) {
			getConsoFromBuilding()
		}
    
      
    }, [filter,from,to])

	useEffect(() => {
		if(buildingData.length > 0) {
			setFilter(buildingData[0].id)
			let batimentsTemporary = [];
			for (let index = 0; index < buildingData.length; index++) {
				batimentsTemporary.push({'name' :buildingData[index].title, 'id': buildingData[index].id})
				
			}
			setBatiments(batimentsTemporary);
		}
        
    
      
    }, [buildingData])
    
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface batiments</h3>
                <p>Visualisez tous vos batiments et leurs informations</p>
            </div>
        </div>
		

        <div id="page-content">

			<div className="row">
				<div className="col-lg-12">

                                                {/* <!--Network Line Chart-->
                                                <!--===================================================--> */}
					<div id="demo-panel-network" className="panel">
						<div className="panel-heading">

								<h3 className="panel-title">Liste des bâtiments</h3>
						</div>
						<div className="col-lg-6">
							<div className="fixed-fluid mar-rgt">
								<div className="pull-sm-left d-inline mar-btm">
								{buildingData.length > 0 && filter.length> 0? <SelectWithouthDefault label={'Batiment'} batimentList={batiments} batiment={filter} setBatiment={setFilter}/> : null}
									
								</div>
							</div>
						</div>

                    <div className="col-lg-6">
                        <div className="fixed-fluid mar-btm">
                            <div className="fixed-sm-250 pull-sm-right">
                                <div id="demo-dp-range">
                                    <div className="input-daterange input-group" id="datepicker">
                                            <DatePickerWitouthMin label='Date Début' main='from' to={to} from={from} setFrom={setFrom}/>
                                            <span className="input-group-addon">to</span>
                                            <DatePickerWitouthMin label='Date Fin' main='to' to={to} from={from} setTo={setTo}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                                        
					<div className="col-lg-12">				
									<div class="table-responsive">
					                    <table style={{overflowX : "scroll"}} class="table table-bordered">
					                        <thead>
					                            <tr>
					                                <th class="text-center" colSpan={3}>Projet</th>
					                                <th class="text-center" colSpan={8}>Contract/Autres</th>
					                                <th class="text-center" colSpan={14}>Conso Gaz</th>
					                                <th class="text-center" colSpan={2}>Analyse</th>
                                                    <th class="text-center" colSpan={5}>Année civile</th>
                                                    <th class="text-center" colSpan={5}>Année civile</th>
                                                    <th class="text-center" colSpan={4}>Température par location</th>
					                            </tr>
                                                <tr>
					                                <th>Projet</th>
					                                <th>Addresse</th>
					                                <th>Remarques</th>

					                                <th>contract grdf</th>
					                                <th>N° PCE</th>
					                                <th>Contrat Enedis</th>
					                                <th>N° X</th>
					                                <th>Contract X</th>
					                                <th>Sonde Extérieur</th>
					                                <th>Audit énergétique</th>
					                                <th>Autres informations</th>
					                                
					                                <th>Jan</th>
					                                <th>Fev</th>
					                                <th>Mar</th>
					                                <th>Avr</th>
					                                <th>Mai</th>
					                                <th>Jun</th>
					                                <th>Jul</th>
					                                <th>Aout</th>
					                                <th>Sept</th>
					                                <th>Oct</th>
					                                <th>Nov</th>
					                                <th>Dec</th>
					                                <th>Année civil</th>
					                                <th>Total annuel</th>

                                                    <th>Année civil</th>
					                                <th>Total annuel</th>

                                                    <th>DJU</th>
                                                    <th>Base 18</th>
                                                    <th>Base 20</th>
                                                    <th>Base 21</th>
                                                    <th>Base 22</th>
                                                    
                                                    <th>DJU</th>
                                                    <th>Base 18</th>
                                                    <th>Base 20</th>
                                                    <th>Base 21</th>
                                                    <th>Base 22</th>

                                                    <th>DJU mensuel</th>
                                                    <th>DJU hebdomadaire</th>
                                                    <th>DJU quotidien</th>
                                                    <th>DJU horaire</th>
					                            </tr>
					                        </thead>
					                        <tbody>
					                            <tr>
					                                <td class="text-center">1</td>
					                                <td><a href="#" class="btn-link">Scott S. Calabrese</a></td>
					                                <td><span class="label label-purple">Bussines</span></td>
					                            </tr>
					                            <tr>
					                                <td class="text-center">2</td>
					                                <td><a href="#" class="btn-link">Teresa L. Doe</a></td>
					                                <td><span class="label label-info">Personal</span></td>
					                            </tr>
					                            <tr>
					                                <td class="text-center">3</td>
					                                <td><a href="#" class="btn-link">Steve N. Horton</a></td>
					                                <td><span class="label label-warning">Trial</span></td>
					                            </tr>
					                            <tr>
					                                <td class="text-center">4</td>
					                                <td><a href="#" class="btn-link">Charles S Boyle</a></td>
					                                <td><span class="label label-purple">Bussines</span></td>
					                            </tr>
					                            <tr>
					                                <td class="text-center">5</td>
					                                <td><a href="#" class="btn-link">Lucy Doe</a></td>
					                                <td><span class="label label-warning">Trial</span></td>
					                            </tr>
					                            <tr>
					                                <td class="text-center">6</td>
					                                <td><a href="#" class="btn-link">Michael Bunr</a></td>
					                                <td><span class="label label-info">Personal</span></td>
					                            </tr>
					                        </tbody>
					                    </table>
					                </div>
									
                                                        {/* <div className='pad-ver'>
                                                            {buildingData.length > 20 ?
                                                            <Pagination count={Math.ceil(buildingData.length/20)} onChange={(e,page) => setPagination(page)} color="primary" />
                                                            :
                                                            null}
                                                        </div> */}
														</div>
                                                </div>
                                                
                                                
                                        </div>
                                </div>


                        </div>
    </div>
  );
}

export default AnalyserBuilding;
