import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { render } from 'react-dom';
import Login from './components/view/Login';
import MainNav from './components/view/MainNav';
import Navbar from './components/view/Navbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './components/view/buildings/index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/authprovider';
import App from './components/App';
import Unauthorized from './components/view/Unauthorized';



// const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");
// console.log(window.localStorage.getItem('sidebar'));
render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path='/*' element={<App/>}/>
        <Route path="/unauthorized" element={<Unauthorized/>}/>
      </Routes>
    </AuthProvider>
  
  </BrowserRouter>, 
  rootElement
)
// root.render(
//   <React.StrictMode>
//     <Login/>
//     {/* <div id='container' className='effect aside-float aside-bright mainnav-lg'>
//     <Navbar/>
//       <div className='boxed'>
//         <MainNav/>
//       </div>
//     </div> */}
    
//   </React.StrictMode>
// );


