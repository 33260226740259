import { useState,useEffect } from "react";

export default function PanelRemove({ children, index = null, onCloseCallback = null }) {

    const [toShow, setToShow] = useState(true)
    const [animation, setAnimation] = useState(false)

    // useEffect(() => {
    //     if (animation === true) {
    //         if (onCloseCallback !== null && index !== null) {
    //             onCloseCallback(index)
    //             console.log('closecallback');
    //         }
    //         setTimeout(() => {
    //             setToShow(false)
    //         }, 1000);
    //     }
        
        
    // }, [animation])

    useEffect(() => {
        let timeoutId;
        
        if (animation === true) {
            // if (onCloseCallback !== null && index !== null) {
            //     onCloseCallback(index);
            //     console.log('closecallback');
            // }
    
            timeoutId = setTimeout(() => {
                setToShow(false);
            }, 1000);
        }
        
        return () => {
            clearTimeout(timeoutId); // Clear the timeout on unmount
        };
    }, [animation]);

    return (
        <div 
        // style={ devicesNumber < 10 ? {height: `${400 + devicesNumber * 10}px`, width: '100%'} : null } 
        style={!toShow ? {display: "none"}: null} 
        className={!animation? "panel" : "panel remove"}>
					

    <div className="panel-heading">
        <div className="panel-control">
            <button onClick={e => setAnimation(true)} className="btn btn-default" data-panel="dismiss"><i className="demo-psi-cross"></i></button>
        </div>
    </div>


    <div className="panel-body">
        {children}
    </div>
</div>
        
    );
  }