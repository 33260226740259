import * as React from 'react';

import TextField from '@mui/material/TextField';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils  from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { subYears,subDays } from 'date-fns'
const color = "#c44242";
export default function BasicDatePicker(props) {
  const [value, setValue] = React.useState(null);
  
  const changeValue = (newValue) => {
      props.main === 'from' ? props.setFrom(newValue) : props.setTo(newValue)
  }
  
  return (
    <LocalizationProvider locale={frLocale} dateAdapter={DateFnsUtils}>
      <DatePicker
        label={props.label}
        views={['day']}
        clearable={true}
        minDate={
            props.main === 'from' ? 
            subYears(new Date(), 1) 
            :
            props.from !== null ? props.from : subYears(new Date(), 1)
        }
        maxDate={
          props.main !=='from' ?
          new Date()
          :
          props.to !== null ?
          props.to 
          :
          new Date()
        }
        inputFormat="dd/MM/yyyy"
        clearText='Effacer'
        placeholder="jj/MM/YYYY"
        toolbarPlaceholder='test'
        value={props.main === 'from' ? props.from : props.to}
        onChange={(newValue) => {
          changeValue(newValue)
        }}
        renderInput={(params) => <TextField {...params} sx={{
            backgroundColor: 'white',
          }} />}
      />
    </LocalizationProvider>
  );
}