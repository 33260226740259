function generateFilterByDepth(obj, depth) {
    // Helper function to traverse the object
    function traverse(currentObj, currentDepth) {
        // Base case: if the current depth is the target depth
        if (currentDepth === depth) {
            // Add the current object's data to the result array
            result.push({ label: currentObj.name, id: currentObj.id });
            return;
        }

        // If there are child entities, traverse them
        if (currentObj.child_entity && currentObj.child_entity.length) {
            currentObj.child_entity.forEach(child => {
                traverse(child, currentDepth + 1);
            });
        }
    }

    // Initialize result array
    let result = [];
    // Start traversal from the root object with initial depth 1
    traverse(obj, 0);

    return result;
}





export default generateFilterByDepth