import {useRef, useState, useEffect} from 'react'
import { useLocation, useNavigate,Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Pagination from '@mui/material/Pagination';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import StreetView from './StreetView';
import { render } from '@testing-library/react';

 
mapboxgl.accessToken = 'pk.eyJ1IjoibGFndW5hbWF4aW1lIiwiYSI6ImNscGdwcXNmajAwaGMya3AwYWRiMHZlNjcifQ.VjM0-jCxQ14pZGojtjZwEw';



function MapBox() {

	const navigate = useNavigate()
	const location = useLocation();
    const {setAuth, auth} = useAuth();
	const from = location.state?.from?.pathname || "/"
    const axiosPrivate = useAxiosPrivate();

    const mapContainer = useRef(null);
const map = useRef(null);
const [lng, setLng] = useState(-0.370679);
const [lat, setLat] = useState(49.182863);
const [zoom, setZoom] = useState(17);

const [renderMap, setRenderMap] = useState(false);


const toggleMap = () => {
    if (renderMap === true) {
        setRenderMap(false)
    } 
    else {
        setRenderMap(true)
    }
}
const dynamicCoordinates = { lat: lat, lng: lng };

useEffect(() => {
  
}, [renderMap])


useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom,
        pitch: 45,
        bearing: -17.6,
        antialias: true
    });

    map.current.on('style.load', () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.current.getStyle().layers;
        const labelLayerId = layers.find(
            (layer) => layer.type === 'symbol' && layer.layout['text-field']
        ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.current.addLayer(
            {
                'id': 'add-3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                    'fill-extrusion-color': '#aaa',

                    // Use an 'interpolate' expression to
                    // add a smooth transition effect to
                    // the buildings as the user zooms in.
                    'fill-extrusion-height': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'height']
                    ],
                    'fill-extrusion-base': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'min_height']
                    ],
                    'fill-extrusion-opacity': 0.6
                }
            },
            labelLayerId
        );
    });

    map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
        });
    });



    const redirectMap = () => {
        const url = 'http://maps.google.com/maps?q=&layer=c&cbll='+lat+','+lng
        window.open(url, "_blank") //to open new page
    }
	
  return (
    <div id="content-container">
        <div id="page-head">
            <div className="pad-all text-center">
                <h3>Interface batiments</h3>
                <p>Visualisez tous vos batiments et leurs informations</p>
            </div>
        </div>

        <div id="page-content">

            <div className="row">
                    <div className="col-lg-12">
                        <button onClick={toggleMap}>render map</button>
                        <button onClick={redirectMap}>render map</button>

                    <div>
                        <div className="sidebar">
                            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                        </div>
                        <div ref={mapContainer} className="map-container" />
                    </div>

{renderMap === true ? <StreetView apiKey={'AIzaSyBwXeRU6XJzw6synRwOGJ-D8HqByTBIhwI'} initialPosition={dynamicCoordinates} />
: null}

<iframe src={'http://maps.google.com/maps?q=&layer=c&cbll='+lat+','+lng}></iframe>
                    {/* <StreetView apiKey={'AIzaSyBwXeRU6XJzw6synRwOGJ-D8HqByTBIhwI'} initialPosition={dynamicCoordinates} /> */}
                            
                            
                    </div>
            </div>
        </div>
    </div>
  );
}

export default MapBox;
