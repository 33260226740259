export default function getValueFromPath(obj, path) {
    // split the path by the dot character
    var keys = path.split(".");
    // initialize a variable to hold the current value
    var value = obj;
    // loop through the keys array
    for (var i = 0; i < keys.length; i++) {
      // get the current key
      var key = keys[i];
      // check if the value is an object and has the key as a property
      if (typeof value === "object" && value !== null && value.hasOwnProperty(key)) {
        // update the value with the property value
        value = value[key];
      } else {
        // return undefined if the key is not found
        return undefined;
      }
    }
    // return the final value
    return value;
  }