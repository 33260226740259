import { Outlet, Route } from "react-router-dom";
import MainNav from "./view/MainNav";
import Navbar from "./view/Navbar";
import RequiredAuth from "./RequireAuth";
import ShowIfAuth from "./ShowIfAuth";
import useAuth from "../hooks/useAuth";

const Layout = () => {
    const {auth} = useAuth();
    return(

auth?.user
? 
<div id='container' className='effect aside-float aside-bright mainnav-lg'>
    <Navbar/>
    <div className="boxed">
        <MainNav/>
        <Outlet/>
    </div>
    
</div>
: 
<div id='container' className='effect aside-float aside-bright mainnav-lg'>
    <div className="content-container">
        <Outlet/>
    </div>
</div>
       
    )
}

export default Layout