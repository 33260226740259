import { useCallback } from 'react';
import axios from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import useRefreshToken from './useRefreshToken';

function useApiCall() {
  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();
  const fetchData = useCallback(async (url, params, requestType, onSuccess, onError) => {
    try {
      let response;
      switch (requestType) {
        case 'GET':
          response = await axiosPrivate.get(url, params);
          break;
        case 'POST':
          response = await axiosPrivate.post(url, params);
          break;
        // Add other request types as needed
        default:
          throw new Error('Request type not supported');
      }
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
    }
  }, []); // Dependencies should include any external values used in the hook

  return fetchData;
}

export default useApiCall;
